import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useRef } from 'react';
import AppContext from '../../../context/app.context/app.context';
import Setting from '../Setting/Setting';
import OutsideAlerter from '../OutsideClick/OutsideClick';
import customLoader from '../../../helpers/custom-image-loader';
import Image from 'next/image';
import AuthServices from '../../../services/api/auth/auth.services';
import GlobalStateStore from '../../../context/store/global-state';
import ModalStore from '../../../context/store/modal-pop-up';
import LanguageIcon from '@mui/icons-material/Language';
import BrandConfigStore from '../../../context/store/brand-config';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

function Sidebar(p) {
	const appContext = useContext(AppContext);
	const { setToken, token } = GlobalStateStore((state) => ({ setToken: state.setToken, token: state.token }));
	const { setModal } = ModalStore((state) => ({ setModal: state.setModal }));
	const { navigation } = BrandConfigStore(state => ({ navigation: state.config.navigation }));
	const router = useRouter();
	const sidebarRef = useRef(null);
	const { isClicked, setIsClicked } = p;

	const logout = () => {
		AuthServices.Logout()
			.then(() => {
				localStorage.removeItem('token');
				localStorage.removeItem('balance');
				appContext.logout();
				setToken(null);
			})
			.catch((error) => {
				console.log(error);
			});
	};

	const sortArr = (a, b) => a.order > b.order ? 1 : -1;

	return (
		<>
			<OutsideAlerter state={setIsClicked} />
			<div
				className="absolute z-40 top-0 left-0 right-0 h-[100vh] sidebar text-[#e9e7ea] font-quicksandBold"
				ref={sidebarRef}>
				<div
					className={`flex flex-col space-y-1 mr-[0.375rem] laptop:hidden relative z-[65] top-[1.5rem] transition-all duration-500 ${
						isClicked ? 'translate-x-[90%]' : 'translate-x-0'
					}`}
					onClick={() => {
						setIsClicked(false);
					}}>
					<div
						className={`w-[1.25rem] h-[0.125rem] bg-white transition-all duration-500 ${
							isClicked && 'rotate-[140deg] translate-y-1'
						}`}
					/>
					<div
						className={`w-[1.25rem] h-[0.125rem] bg-white transition-all duration-300 ${
							isClicked ? 'scale-0 opacity-0 rotate-180 w-0' : 'scale-1 opacity-100 '
						}`}
					/>
					<div
						className={`w-[1.25rem] h-[0.125rem] bg-white transition-all duration-700 ${
							isClicked && '-rotate-[140deg] -translate-y-2'
						}`}
					/>
				</div>

				<div
					className="flex sidebar-header w-[75%]"
					onClick={() => router.push('/account/profile/information')}>
					{token && !token.toLowerCase().includes('sessiontoken') ? (
						<div className="block w-full tablet:rounded-t-[1rem] p-[1rem_1.5rem] cursor-pointer"
							onClick={() => {
								router.push('/account/profile/information/');
							}}>
							<div className="flex z-10 text-[2rem] leading-none">
								{appContext.userInfo?.fullName || appContext.userInfo.username}
							</div>
							<div>{appContext.userInfo.memberLevel}</div>
						</div>
					) : null}
				</div>

				<div>
					<div className="pt-6 mx-4">
						{token && !token.toLowerCase().includes('sessiontoken') ? (
							<div className="border-b pb-2 mb-2">
								{navigation.profile.sort(sortArr).map((mainMenuItem) => (
									<Link
										className={mainMenuItem.current ? '' : 'disabled'}
										key={mainMenuItem.displayName}
										href={mainMenuItem.path}>
										<a
											className={classNames(
												router.asPath.includes(mainMenuItem.parentPath) ? 'active-sidebar' : '',
												'group flex items-center px-2 py-2'
											)}>
											<Setting
												title={appContext.t(mainMenuItem.displayName)}
												Icon={mainMenuItem.icon}
												current={true}
											/>
										</a>
									</Link>
								)
								)}
							</div>
						): null }

						{navigation.sideBar.sort(sortArr).map((item) => (
							item.enabled ? (
								<a
									key={item.page}
									href={item.page}
									className={classNames(
										router.asPath.includes(item.page) ? 'active-sidebar w-full' : '',
										'group flex items-center px-2 py-2'
									)}>
									<Setting title={appContext.t(item.name)} Icon={item.icon} current={true} />
								</a>
							) : null
						))}
						<button
							className="font-quicksandRegular font-medium space-x-3 text-xl side-menu text-[#e7e9ea] flex items-center px-2 py-2"
							onClick={() => setModal({ type: 'language', mode: 'language' })}>
							<LanguageIcon />
							<span>{appContext.t('text.language')}</span>
						</button>
					</div>

					{token && !token.toLowerCase().includes('sessiontoken') ? (
						<div
							className={'flex w-full px-[1.5rem] pt-[0.5rem] pb-[1rem] cursor-pointer space-x-2'}
							onClick={() => {
								logout();
								router.push('/');
							}}>
							<div className={'flex'}>
								<Image
									src={'/icons/Navbar/logout.svg'}
									className={'flex items-center'}
									loader={customLoader}
									width={25}
									height={25}
								/>
							</div>
							<div className={'flex items-center text-red-500 text-[18px]'}>
								{appContext.t('menu.logout')}
							</div>
						</div>
					) : null}
				</div>
			</div>
		</>
	);
}

export default Sidebar;
