import BrandConfigStore from '../../../context/store/brand-config';

const Button = (p) => {
	const { children, className, ...rest } = p;
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);

	return (
		<button
			style={{ backgroundColor: backgroundColor.secondary, color: colors.primary }}
			className={`btn rounded-lg btn-md text-sm w-fit px-5 ${className}`}
			{...rest}
		>{children}</button>
	);
};

export { Button };
