import { twMerge } from 'tailwind-merge';

const IconButton = (p) => {
	const { className, icon, ...rest} = p;

	return (
		<button type="button" className={twMerge('btn btn-square w-fit h-fit p-1 bg-inherit border-none hover:bg-inherit active:border-0 focus:border-0 focus:outline-none focus-visible:outline-none', className)} {...rest}>
			{icon}
		</button>
	);
};

export { IconButton };
