/* eslint-disable no-useless-escape */
const isRequired = (value) => (value === '' ? false : true);

const isBetween = (length, min, max) =>
	length < min || length > max ? false : true;

const isEmailValid = (email) => {
	const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
};

const isPasswordSecure = (password) => {
	const re = new RegExp(
		'^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
	);
	return re.test(password);
};
const allLetter = (value) => {
	const re = /^[A-Za-z\s]*$/;
	return re.test(value);
};
const alphanumeric = (alphaNum) => {
	const re = /^[0-9a-zA-Z]+$/;
	return re.test(alphaNum);
};
const allnumeric = (nums) => {
	const re = /^[0-9]+$/;
	return re.test(nums);
};

const correctDateFormat = (date) => {
	const re = /^\d{4}-\d{2}-\d{2}$/;
	return re.test(date);
};

const Validation = {
	isRequired,
	isBetween,
	isEmailValid,
	isPasswordSecure,
	allLetter,
	alphanumeric,
	allnumeric,
	correctDateFormat,
};

export default Validation;
