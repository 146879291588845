import { ApolloProvider } from '@apollo/client';
import client from '../../../services/ApolloClient';

const AffiliateContainer = (p) => {
	const { children } = p;

	return (
		<ApolloProvider client={client}>
			<div className="text-white container mx-auto z-0 min-h-screen py-4">
				<main>{children}</main>
			</div>
		</ApolloProvider>
	);
};

export default AffiliateContainer;