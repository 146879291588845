import BrandConfigStore from '../../../context/store/brand-config';
import Navbar from '../../organism/Navbar/Navbar';
import { useRouter } from 'next/router';

export default function BottomNavbar() {
	const router = useRouter();
	const { config } = BrandConfigStore(state => ({ config: state.config }));
	const { backgroundColor, colors } = config.themeConfig.navbar;
	const { navigation } = config;

	return router.pathname != '/play' ? (
		<div
			className="border-b py-2 fixed bottom-0 z-[70] flex items-center justify-evenly md:hidden w-full overflow-x-auto"
			style={{
				background: backgroundColor.primary,
				color: colors.primary,
			}}>
			<Navbar options={navigation.navBar} />
		</div>
	) : null;
}
