import { useEffect, useState } from 'react';
import Footers from '../../organism/Footer/Footers';
import Header from '../../organism/Header/Header';
import Container from '../../organism/Container/Container';
import ModalLayout from './ModalLayout';
import BottomNavbar from '../../organism/Navbar/BottomNavbar';
import { useRouter } from 'next/router';
import { ModalContainer } from '../../../components2/templates/ModalLayout/modal';

function Layout(p) {
	const { children } = p;
	const router = useRouter();

	const [userAgent, setUserAgent] = useState({
		isMobile: null,
		name: ''
	});

	useEffect(() => {
		if (window) {
			setUserAgent({
				...userAgent,
				name: window.navigator.vendor,
				isMobile: 'ontouchstart' in document.documentElement
			});
		}
	}, []);

	return (
		<ModalLayout {...p}>
			<ModalContainer />
			<Header {...p} />

			<Container {...p}>{children}</Container>

			{(router.pathname == '/play' && userAgent.isMobile) || router.pathname == '/play' ? null : (
				<Footers {...p} />
			)}

			{router.pathname == '/play' && userAgent.isMobile
				? null
				: userAgent.isMobile && <BottomNavbar {...p} />}
		</ModalLayout>
	);
}

export default Layout;
