const Transaction = `
    mutation CreateTransaction (
            $amount: Int!,
            $bank: String!,
            $transactionType: TransactionType,
            $username: String!
        ) {
            createTransaction(
                input: {
                    amount: $amount,
                    bank: $bank,
                    transactionType: $transactionType,
                    username: $username
                }
        ) {
            amount
            bank
            id
            transactionDate
            transactionType
            username
        }
    }
`;

const TransactionMutation = {
	Transaction,
};

export default TransactionMutation;
