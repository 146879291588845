import NavItems from '../../molecules/NavItems/NavItems';
import { useContext } from 'react';
import AppContext from '../../../context/app.context/app.context';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
import { useRouter } from 'next/router';

function Navbar(p) {
	const appContext = useContext(AppContext);
	const { liveChatScriptMobile } = BrandConfigStore(state => state.config);
	const { headerHeight, setUrl } = GlobalStateStore(state => ({ token: state.token, menuClicked: state.menuClicked, headerHeight: state.headerHeight, setUrl: state.setUrl }));
	// const notSessionToken = token && token.split(':')[0].toLowerCase() !== 'sessiontoken';
	const router = useRouter();
	const { options } = p;

	const handleClick = (id) => {

		if (id.includes('#')) {
			if (router.pathname !== '/') {
				setUrl(id);
				router.push('/');
			}

			const elm = document.querySelector(id);
			window.scrollTo(0, elm?.getBoundingClientRect().top + window.scrollY - headerHeight);
		} else {
			router.push(`/${id}`);
		}
	};

	const sortArr = (a, b) => a.order > b.order ? 1 : -1;
	
	return (
		<nav
			className="myContainer grid md:flex gap-9 tablet:gap-5"
			id="navbar"
			style={{
				// gridTemplateColumns: `repeat(${(notSessionToken ? navItems.length + 1 : navItems.length) + (liveChatScriptMobile ? 1 : 0)}, minmax(0, 2fr))`
				gridTemplateColumns: `repeat(${(options.length + 1) + (liveChatScriptMobile ? 1 : 0)}, minmax(0, 2fr))`
			}}
		>
			<div className="md:hidden flex justify-center">
				<button  
					onClick={() => {
						appContext.setOpenSidebar(!appContext.openSidebar);
					}}
				>
					<NavItems
						title={appContext.t('text.menu')}
						icon={'/icons/Navbar/menu.svg'}
						url=""
					/>
				</button>
			</div>

			{options.sort(sortArr).map((item) => {
				if (item.enabled) {
					return (
						item.page.includes('casino') || item.page.includes('slots') ? (
							<a href={item.page} key={item.name}>
								<NavItems
									title={appContext.t(item.name)}
									icon={item.icon}
									url={item.page}
								/>
							</a>
						) : (
	
							<button key={item.name} onClick={() => handleClick(item.page.slice(1), (item.page.includes('casino')))}>
								<NavItems
									title={appContext.t(item.name)}
									icon={item.icon}
									url={item.page}
								/>
							</button>
						)
					);
				}	
			})}

			{liveChatScriptMobile ? (
				<a className="md:hidden" href={liveChatScriptMobile} target="_blank" rel="noreferrer">
					<NavItems
						title="Chat"
						icon="/icons/NavMenu/chat.svg"
						url="#"
					/>
				</a>
			) : null}
		</nav>
	);
}

export default Navbar;
