import dynamic from 'next/dynamic';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
import { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../../context/app.context/app.context';
import AuthServices from '../../../services/api/auth/auth.services';
import BrandSetting from '../../../../public/brandSettings.json';
const Turnstile = dynamic(() => import('@marsidev/react-turnstile').then((r) => r.Turnstile), {
	ssr: false
});

// eslint-disable-next-line react/display-name
const CFTurnstile = ({ id, className, onError }) => {
	const brandConfig = BrandConfigStore((state) => state.config);
	const appContext = useContext(AppContext);

	const [triggerTurnstile, setTriggerTurnstile] = useState(false);

	const ref = useRef();

	const { token, setToken, tsToken, setTsToken, clearTsToken, fingerprint, isTokenValid } =
		GlobalStateStore((state) => ({
			token: state.token,
			tsToken: state.tsToken,
			setTsToken: state.setTsToken,
			setToken: state.setToken,
			clearTsToken: state.clearTsToken,
			fingerprint: state.fingerprint,
			isTokenValid: state.isTokenValid
		}));

	// useEffect(() => {
	// 	if (ref.current) {
	// 		console.log('ref : ', ref);
	// 	}
	// }, [ref]);

	useEffect(() => {
		// console.log('token : ', token);
		if (
			(!appContext.isLoggedIn && !appContext.isLoading && !token) ||
			(!appContext.isLoggedIn && !appContext.isLoading && token && token !== '' && !isTokenValid)
		) {
			setTriggerTurnstile(true);
			// console.log('ref : ', ref);
			ref.current?.remove;
		} else {
			setTriggerTurnstile(false);
		}
	}, [appContext.isLoggedIn, appContext.isLoading, token, isTokenValid]);

	useEffect(() => {
		if (tsToken && tsToken !== '' && fingerprint && fingerprint !== '' && ref.current) {
			AuthServices.TsGetSessionToken({
				tsToken: tsToken,
				fingerprint: fingerprint,
				brandId: BrandSetting.brandId
			})
				.then((res) => {
					localStorage.setItem('sessionToken', res?.data?.sessionToken);
					setToken(res?.data?.sessionToken);
					clearTsToken();
					ref.current?.remove;
					setTriggerTurnstile(false);
					// setIsSessionError(false);
				})
				.catch(() => {
					ref.current?.remove;
					// setTriggerTurnstile(true);
					// setIsSessionError(true);
				});
		}
	}, [tsToken, fingerprint]);

	return (
		<>
			{triggerTurnstile && (
				<Turnstile
					ref={ref}
					siteKey={brandConfig.serverSecret}
					onSuccess={(x) => {
						clearTsToken();
						setTsToken(x);
					}}
					// onExpire={() => {
					// 	setTsTokenValid(false);
					// }}
					onError={onError}
					theme="auto"
					id={id}
					className={className ?? ''}
					options={{
						// 	refreshExpired: 'auto',
						size: 'invisible'
					}}
				/>
			)}
		</>
	);
};

export default CFTurnstile;
