import LoginCard from '../../../components/molecules/Popup/LoginCard';
import RegisterCard from '../../../components/molecules/Popup/RegisterCard';
import ForgotPassword from '../../../components/molecules/Popup/forgot-password';
import { LanguageModal } from '../../../components/molecules/Popup/language-popup';
import { AlertModal } from '../../../components/molecules/Popup/notification-alert';
import TopUpBalanceTogel from '../../../components/molecules/Popup/topup-balance-togel';

const ModalContainer = () => {
	return (
		<>
			<RegisterCard />
			<LoginCard />
			<ForgotPassword />
			<TopUpBalanceTogel />
			<LanguageModal />
			<AlertModal />
		</>
	);
};

export { ModalContainer };
