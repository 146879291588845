import LoadingCircle from '../../../components/molecules/Loading/LoadingCircle';
import BrandConfigStore from '../../../context/store/brand-config';

const SubmitButton = (p) => {
	const { isClicked, children, className, ...rest} = p;
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);

	return (
		<button
			className={`btn w-full ${className}`}
			type="submit"
			disabled={isClicked}
			style={{
				backgroundColor: backgroundColor.accent2,
				color: colors.accent
			}}
			{...rest}
		>
			{isClicked ? <LoadingCircle bodyClassName="h-4" /> : children}
		</button>
	);
};

export { SubmitButton };
