import { useContext, useEffect, useState } from 'react';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
import { Modal, ModalContent, ModalHeader } from '../../../components2/atom/modals';
import CurrencyInput from 'react-currency-input-field';
import ModalStore from '../../../context/store/modal-pop-up';
import CurrencyFormatHelper from '../../../helpers/currency-format.helper';
import Constant from '../../../services/api/constant/constant';
import AppContext from '../../../context/app.context/app.context';

const initInput = {
	transferFrom: {
		transferFromCurrency: '',
		transferFromAmount: ''
	},
	transferTo: {
		transferToCurrency: '',
		transferToAmount: ''
	}
};

const TopUpBalanceTogel = () => {
	const { backgroundColor, colors } = BrandConfigStore(
		(state) => state.config.themeConfig.container
	);
	const { type, mode, closeModal } = ModalStore((state) => state);
	const appContext = useContext(AppContext);
	const { balances, token, toast, toastStatus } = GlobalStateStore((state) => ({
		balances: state.balances,
		token: state.token,
		toast: state.toast,
		toastStatus: state.toastStatus
	}));

	const [transferFrom, setTransferFrom] = useState(initInput.transferFrom);
	const [transferTo, setTransferTo] = useState(initInput.transferTo);
	const [isClicked, setIsClicked] = useState(false);
	const [inGameCurrencyList, setInGameCurrencyList] = useState([]);
	const [balanceList, setBalanceList] = useState([]);
	const [userAgent, setUserAgent] = useState({
		isMobile: null,
		name: ''
	});

	const gp = type.split('-')[0].toUpperCase();

	const isOpen = mode === 'topup';

	const countExchange = async () => {
		const payload = {
			systemType: 'transferBalance',
			type: 'inGameDepositAmount',
			gpId: gp,
			walletCurrency: transferFrom.transferFromCurrency,
			inGameCurrency: transferTo.transferToCurrency,
			amount: Number(transferFrom.transferFromAmount)
		};

		try {
			const response = await Constant.TransferGameService(payload);

			setTransferTo((prev) => ({ ...prev, transferToAmount: response.data.inGameAmount }));
		} catch (error) {
			console.log(error);
			toast(toastStatus.ERROR, error);
		}
	};

	const validate = () => {
		if (!transferFrom.transferFromAmount || transferFrom.transferFromAmount < 1)
			throw Error('Amount must be higher than 0');
	};

	const getGame = (message) => {
		setIsClicked(true);

		try {
			const newWindow = window.open(
				`/play/?gpId=${type.split('-')[0]}&gameId=${type.split('-')[1]}`
			);
			if (!newWindow) {
				toast(toastStatus.ERROR, 'Window popup is blocked, please allow window popup.');
			} else {
				if (message) toast(toastStatus.SUCCESS, 'Success');
			}
			closeModalHandler();
		} catch (error) {
			console.log(error);
			toast(toastStatus.ERROR, 'Something went wrong');
		} finally {
			setIsClicked(false);
		}
	};

	const submitHandler = async (e) => {
		e.preventDefault();
		setIsClicked(true);
		const payload = {
			systemType: 'transferBalance',
			type: 'deposit',
			gpId: 'HKB',
			walletCurrency: transferFrom.transferFromCurrency,
			inGameCurrency: transferTo.transferToCurrency,
			amount: Number(transferFrom.transferFromAmount)
		};

		try {
			validate();
			await Constant.TransferGameService(payload);
			getGame('success');
		} catch (error) {
			setIsClicked(false);
			toast(toastStatus.ERROR, error.message);
		}
	};

	const getGameProviderData = async () => {
		const payload = {
			systemType: 'transferBalance',
			type: 'init'
		};

		try {
			const response = await Constant.TransferGameService(payload);

			const { gameProvider } = response.data;
			const hkb = gameProvider.filter((gp) => gp.gpid === 'HKB')[0];

			const inGameCurrencyObj = JSON.parse(hkb.in_game_currency);
			setTransferTo((prev) => ({ ...prev, transferToCurrency: inGameCurrencyObj[0] }));
			setInGameCurrencyList(inGameCurrencyObj);
		} catch (error) {
			toast(toastStatus.ERROR, error.message);
		}
	};

	const closeModalHandler = () => {
		closeModal();
		setTransferFrom(initInput.transferFrom);
		setTransferTo(initInput.transferTo);
		setIsClicked(false);
	};

	useEffect(() => {
		if (window) {
			setUserAgent({
				...userAgent,
				name: window.navigator.vendor,
				isMobile: 'ontouchstart' in document.documentElement
			});
		}
	}, [userAgent.name]);

	useEffect(() => {
		const refined = Object.keys(balances.walletBalance ?? {}).map((c) => ({
			id: c,
			label: c
		}));

		setTransferFrom((prev) => ({ ...prev, transferFromCurrency: balances.activeWallet }));

		setBalanceList(refined);
	}, [balances, isOpen]);

	useEffect(() => {
		if (token && token.split(':')[0].toLowerCase() !== 'sessiontoken' && isOpen) {
			getGameProviderData();
		}
	}, [isOpen]);

	useEffect(() => {
		if (!transferFrom.transferFromAmount || transferFrom.transferFromAmount < 1) {
			setTransferTo((prev) => ({ ...prev, transferToAmount: 0 }));
			return;
		}

		const timeout = setTimeout(() => {
			countExchange();
		}, 1000);

		return () => clearTimeout(timeout);
	}, [transferFrom, transferTo.transferToCurrency, isOpen]);

	const modalProps = {
		openModal: isOpen,
		setOpenModal: closeModalHandler
	};

	return (
		<Modal {...modalProps}>
			<ModalHeader {...modalProps} closeButton>
				<p className="text-xl">
					{appContext.t('text.topupBalanceTo')} {gp}
				</p>
			</ModalHeader>

			<ModalContent>
				<form className="space-y-4" onSubmit={submitHandler}>
					{balances ? (
						<>
							<FormTemplate
								label={appContext.t('text.transferFrom')}
								balances={balances}
								currency={transferFrom.transferFromCurrency}
								amount={CurrencyFormatHelper.balanceCurrency(
									balances?.walletBalance?.[transferFrom.transferFromCurrency]?.totalBalance ?? 0
								)}>
								<select
									value={transferFrom.transferFromCurrency}
									onChange={(e) =>
										setTransferFrom((prev) => ({ ...prev, transferFromCurrency: e.target.value }))
									}
									className="select bg-inherit border-none ring-0 outline-none focus:outline-none focus:ring-0"
									style={{ backgroundColor: backgroundColor.tertiary }}>
									{balanceList.map((c) => (
										<option key={c.id} value={c.id}>
											{c.label}
										</option>
									))}
								</select>
								<CurrencyInput
									className={`w-full border-none ring-0 outline-none focus:outline-none focus:ring-0 ${
										typeof transferFrom.transferFromAmount !== 'undefined' &&
										transferFrom.transferFromAmount !== '' &&
										'text-end'
									}`}
									placeholder={appContext.t('thead.amount')}
									style={{ backgroundColor: 'inherit' }}
									value={transferFrom.transferFromAmount}
									onValueChange={(val) =>
										setTransferFrom((prev) => ({ ...prev, transferFromAmount: val }))
									}
									decimalsLimit={10}
									min={0}
								/>
								<button
									type="button"
									tabIndex={-1}
									className="btn-ghost"
									onClick={(e) => {
										e.preventDefault();
										setTransferFrom((prev) => ({
											...prev,
											transferFromAmount:
												balances.walletBalance[transferFrom.transferFromCurrency].totalBalance
										}));
									}}>
									Max
								</button>
							</FormTemplate>

							<FormTemplate label={`${appContext.t('text.transferTo')} ${gp}`}>
								<select
									value={transferTo.transferToCurrency}
									onChange={(e) =>
										setTransferFrom((prev) => ({ ...prev, transferToCurrency: e.target.value }))
									}
									className="select bg-inherit border-none ring-0 outline-none focus:outline-none focus:ring-0"
									style={{ backgroundColor: backgroundColor.tertiary }}>
									{inGameCurrencyList.map((c) => (
										<option key={c} value={c}>
											{c}
										</option>
									))}
								</select>
								<p className="text-end w-full">
									{CurrencyFormatHelper.balanceCurrency(transferTo.transferToAmount, 2)}
								</p>
							</FormTemplate>
						</>
					) : (
						<p>Loading</p>
					)}

					{isClicked ? (
						<div className="text-center">
							<span className="loading loading-spinner loading-sm" />
						</div>
					) : (
						<>
							<button
								type="submit"
								className="btn w-full rounded-full"
								style={{ backgroundColor: backgroundColor.accent2, color: colors.primary }}>
								{appContext.t('btn.transferBalance')}
							</button>
							<button
								type="button"
								className="btn w-full btn-ghost"
								onClick={getGame}
								style={{ color: colors.primary }}
								disabled={isClicked}>
								{appContext.t('btn.withoutBalance')}
							</button>
						</>
					)}
				</form>
			</ModalContent>
		</Modal>
	);
};

const FormTemplate = (p) => {
	const { colors } = BrandConfigStore((state) => state.config.themeConfig.container);
	const { label, amount, children } = p;
	const appContext = useContext(AppContext);

	return (
		<div className="space-y-2 bg-inherit" style={{ color: colors.primary }}>
			<p>{label}</p>
			<div className="flex items-center border-b">{children}</div>
			{amount && (
				<p className="text-sm text-end">
					{appContext.t('text.available')} {amount}
				</p>
			)}
		</div>
	);
};

export default TopUpBalanceTogel;
