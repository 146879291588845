String.prototype.toProperCase = function () {
	return this.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
	});
};

const capitalizeFirstLetter = (str) => {
	if (typeof str !== 'string') return '';
	const newString = str.toLowerCase().split(' ');
	return newString.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ');
};

function convertCamelCaseToSpaces(str) {
	if (!str) return null;
	return str.replace(/([a-z])([A-Z])/g, '$1 $2').toLowerCase();
}

const CustomString = {
	capitalizeFirstLetter,
	convertCamelCaseToSpaces,
};

export default CustomString;
