import { t } from '../helpers/t';
import * as yup from 'yup';

export const regisSchema = yup.object({
	username: yup
		.string()
		.required(t('error.register.username.required'))
		.min(5, t('error.register.username.min'))
		.max(15, t('error.register.username.max'))
		.test('isAlphanumeric', t('error.register.username.alpha'), (value) => value.match(/^[a-zA-Z0-9]+$/)),
	email: yup
		.string()
		.email(t('error.register.email.invalid'))
		.required(t('error.register.email.required')),
	password: yup
		.string()
		.required(t('error.register.password.required'))
		.min(8, t('error.register.password.min'))
		.max(32, t('error.register.password.max'))
		.test('isSecure', t('error.register.password.secure'), (value) => value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,})/)),
	phoneNumber: yup
		.string()
		.required(t('error.register.phone.required'))
		.min(5, t('error.register.phone.min'))
		.max(16, t('error.register.phone.max'))
		.test('isNumber', t('error.register.phone.allnumeric'), (value) => value.match(/^\d+$/)),
	countryId: yup
		.string().min(1, (t('error.register.phone.country'))),
	terms: yup.boolean().oneOf([true], t('error.register.checkbox.agree')),
	referralCode: yup
		.string()
		.nullable(),
	currency: yup
		.string()
		.test('currency', t('error.register.currency.required'), function(value) {
			const { paymentAccountName, paymentAccountNumber } = this.parent;
			if ((paymentAccountName || paymentAccountNumber) && !value) return false;
			return true;
		}),
	paymentAccountName: yup
		.string()
		.test('paymentAccountName', t('error.register.paymentAccountName.required'), function(value) {
			const { currency, paymentAccountNumber } = this.parent;
			if ((currency || paymentAccountNumber ) && !value) return false;
			return true;
		}),
	paymentAccountNumber: yup
		.string()
		.test('paymentAccountNumber', t('error.register.paymentAccountNumber.required'), function(value) {
			const { paymentAccountName, currency } = this.parent;
			if ((paymentAccountName || currency ) && !value) return false;
			if (value && !value.match(/^\d+$/)) return this.createError({ path: 'paymentAccountNumber', message: t('error.register.paymentAccountNumber.allnumeric') });
			return true;
		}),
	bank: yup
		.string()
		.test('bank', t('error.register.paymentTypeName.required'), function(value) {
			const { paymentAccountName, paymentAccountNumber, currency } = this.parent;
			if ((paymentAccountName || paymentAccountNumber || currency) && !value) return false;
			return true;
		}),
});
