import { useContext, useState } from 'react';
import AppContext from '../../../context/app.context/app.context';
import AuthServices from '../../../services/api/auth/auth.services';
import LoadingCircle from '../Loading/LoadingCircle';
import { ShowPasswordButton } from '../../../components2/molecules/buttons';
import BrandConfigStore from '../../../context/store/brand-config';
import { Modal } from '../../../components2/atom/modals/Modal';
import { ModalHeader } from '../../../components2/atom/modals/ModalHeader';
import { ModalContent } from '../../../components2/atom/modals/ModalContent';
import GlobalStateStore from '../../../context/store/global-state';
import { shallow } from 'zustand/shallow';
import ModalStore from '../../../context/store/modal-pop-up';
import { InputTextForm } from '../../../components2';
import { useForm } from 'react-hook-form';
import { t } from '../../../helpers/t';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ModalFooter } from '../../../components2/atom/modals';

const loginSchema = yup.object().shape({
	username: yup.string().required(t('error.login.username')),
	password: yup.string().required(t('error.login.password'))
});

const LoginCard = () => {
	const { themeConfig, brandId } = BrandConfigStore((state) => state.config);
	const { backgroundColor, colors } = themeConfig.container;
	const { toast, toastStatus, token, setToken, fingerprint } = GlobalStateStore(
		(state) => state,
		shallow
	);
	const { setModal, closeModal, mode, type } = ModalStore((state) => state, shallow);
	const appContext = useContext(AppContext);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(loginSchema)
	});
	const isLogin = mode === 'login' && type === 'login';

	// const [authToken, setAuthToken] = useState('');
	const [isClicked, setIsClicked] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const login = (data) => {
		if (token && token !== '' && fingerprint && fingerprint !== '') {
			const { username, password } = data;
			const payload = {
				brandId: brandId,
				username: username,
				password: password
			};

			setIsClicked(true);
			AuthServices.Login3(payload, fingerprint, token)
				.then((token) => {
					appContext.setToken(token.playerToken);
					localStorage.removeItem('sessionToken');
					closeModalHandler();
					toast(toastStatus.SUCCESS, 'Success');
					setToken(token.playerToken);
					reset();
					// google tag manager
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						brandId: payload.brandId,
						loginSuccess: true
					});
				})
				.catch(() => {
					toast(toastStatus.ERROR, appContext.t('text.loginFeedBack'));
					// setAuthToken('');
				})
				.finally(() => {
					setIsClicked(false);
				});
		} else {
			console.log('something went wrong');
		}
	};

	const closeModalHandler = () => {
		setShowPassword(false);
		// setAuthToken('');
		closeModal();
		reset();
	};

	const statePop = {
		openModal: isLogin,
		setOpenModal: closeModalHandler
	};

	return (
		<>
			{/* {isLogin && authToken === '' ? (<CFTurnstile {...statePop} className="absolute top-0 scale-0" id="login-turnstile" onSuccess={setAuthToken} />) : null} */}
			<Modal {...statePop}>
				<ModalHeader {...statePop} closeButton />
				<form onSubmit={handleSubmit(login)} className="space-y-6" noValidate>
					<ModalContent>
						<div className="space-y-2">
							<InputTextForm
								label={appContext.t('label.formUserName')}
								labelTop
								labelClassName="text-xl"
								inputClassName="rounded-none"
								placeholder={appContext.t('input.userNamePlaceholder')}
								register={register}
								name="username"
								inputStyle={{
									borderBottom: '1px solid',
									borderColor: errors.username ? 'red' : backgroundColor.secondary
								}}
							/>

							{errors.username ? <p className="text-red-500">{errors.username?.message}</p> : null}
						</div>

						<div className="space-y-2">
							<InputTextForm
								label={appContext.t('label.formPassword')}
								type={showPassword ? 'text' : 'password'}
								labelTop
								labelClassName="text-xl"
								inputClassName="rounded-none"
								register={register}
								placeholder={appContext.t('input.passwordPlaceholder')}
								name="password"
								inputStyle={{
									borderBottom: '1px solid',
									borderColor: errors.password ? 'red' : backgroundColor.secondary
								}}
								endAdornment={
									<ShowPasswordButton
										{...statePop}
										showPassword={showPassword}
										setShowPassword={setShowPassword}
									/>
								}
							/>
							{errors.password ? <p className="text-red-500">{errors.password?.message}</p> : null}
						</div>

						<div className="flex justify-end">
							<button
								tabIndex={-1}
								type="button"
								onClick={(e) => {
									e.preventDefault();
									closeModalHandler();
									setModal({ mode: 'forgot', type: 'forgot' });
								}}
								className="text-xs">
								{appContext.t('btn.forgotPassword')}
							</button>
						</div>
					</ModalContent>

					<ModalFooter className="flex-col">
						<button
							className="btn btn-block py-2.5 rounded-full"
							disabled={isClicked || !token}
							type="submit"
							style={{
								backgroundColor: backgroundColor.accent2,
								filter: !token && 'brightness(50%)',
								color: colors.accent
							}}>
							{token ? (
								isClicked ? (
									<LoadingCircle bodyClassName="h-4" />
								) : (
									appContext.t('btn.login')
								)
							) : (
								<span
									className="loading loading-sm loading-dots"
									style={{ color: colors.primary }}
								/>
							)}
						</button>

						<p className="text-center text-sm" style={{ color: colors.primary }}>
							{appContext.t('text.newAccountPrompt')}
							<button
								type="button"
								onClick={() => {
									closeModalHandler();
									setModal({ mode: 'register', type: 'register' });
								}}
								disabled={isClicked}
								className="inline underline">
								{appContext.t('link.registerHere')}
							</button>
						</p>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

export default LoginCard;
