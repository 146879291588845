const rupiahCurrency = (number) => {
	return 'Rp ' + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const dollarCurrency = (number) => {
	const result = Number(parseFloat(number).toFixed(2)).toLocaleString('en', {
		maximumFractionDigits: 2,
	});
	return result;
};

const currency = (number, numberOfComma) => {
	const result = Number(parseFloat(number).toFixed(10)).toLocaleString('en', {
		maximumFractionDigits: numberOfComma || 5,
	});

	return result;
};

const balanceCurrency = (number, numberOfComma) => {
	const result = Number(parseFloat(number).toFixed(10)).toLocaleString('en', {
		maximumFractionDigits: numberOfComma || 6,
	});

	const numberString = result.toString();
	const dotPosition = numberString.includes('.')
		? numberString.indexOf('.')
		: numberString.length;

	const beforeDot = numberString.substr(0, dotPosition);
	let afterDot = numberString.substr(dotPosition + 1, numberString.length);

	if (beforeDot.length > 5) {
		afterDot =
			afterDot.length > 2
				? numberString.substr(dotPosition + 1, 2) + '...'
				: numberString.substr(dotPosition + 1, 2);
	}

	const finalResult = beforeDot.concat(afterDot ? `.${afterDot}` : '');

	return finalResult;
};

const numberFormat = (number, numberOfComma) => {
	if (parseInt(number) === 'NaN') return '0';

	return Intl.NumberFormat('en', { maximumFractionDigits: numberOfComma || 5, }).format(number);
};

const CurrencyFormatHelper = {
	rupiahCurrency,
	dollarCurrency,
	currency,
	balanceCurrency,
	numberFormat,
};

export default CurrencyFormatHelper;
