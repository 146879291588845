import { IoMdClose } from 'react-icons/io';
import { IconButton } from '../buttons';
import BrandConfigStore from '../../../context/store/brand-config';
import { twMerge } from 'tailwind-merge';

const ModalHeader = (p) => {
	const { children, className = null, closeButton, setOpenModal } = p;
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);

	const closeHandler = () => {
		setOpenModal();
	};

	return (
		<div className={twMerge('sticky flex w-full z-10 items-center min-h-fit top-0 right-0 text-lg font-semibold', className)} style={{ color: colors.primary }}>
			{closeButton && (
				<IconButton onClick={closeHandler} className="absolute top-0 right-0" icon={<IoMdClose className="text-2xl bg-inherit" style={{ color: colors.primary }} />} />
			)}
			<div className="w-full">{children}</div>
		</div>
	);
};

export { ModalHeader };
