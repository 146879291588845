import React from 'react';

const AppContext = React.createContext({
	userInfo: [],
	setUserInfo: () => {},
	token: null,
	setToken: function () {},
	affiliateUserInfo: [],
	setAffiliateUserInfo: () => {},
	affiliateToken: null,
	setAffiliateToken: function () {},
	logout: () => {
		localStorage.removeItem('token');
		localStorage.removeItem('balance');
	},
	affiliateLogout: () => {
		localStorage.removeItem('affiliateToken');
	},
	isLoading: true,
	isLoggedIn: false,
	isAffiliateLoading: true,
	balanceList: null,
	mainBalance: null,
	mainBalanceType: null,
	setMainBalanceType: function () {},
	setMainBalance: function () {},
	changeMainBalance: null,
	setChangeMainBalance: function () {},
	refreshPlayPage: null,
	setRefreshPlayPage: () => {},
	popLogin: null,
	setPopLogin: () => {},
	comingSoon: null,
	setComingSoon: () => {},
	popRegister: null,
	setPopRegister: () => {},
	openSidebar: null,
	setOpenSidebar: () => {},
	t: () => {}
});

export default AppContext;
