/* eslint-disable react/display-name */
import Data from '../../../public/data/newPresets.json';
import { useContext } from 'react';
import AppContext from '../../context/app.context/app.context';

export const withData = (Component) => (props) => {
	const appContext = useContext(AppContext);
	const currentUser = {
		firstName: appContext.userInfo.firstName
			? appContext.userInfo.firstName
			: '',
		lastName: appContext.userInfo.lastName ? appContext.userInfo.lastName : '',
		userID: appContext.user ? appContext.user : 'username',
		email: appContext.userInfo.email ? appContext.userInfo.email : '',
		// password: "Letme1n!",
		address: {
			street: appContext.userInfo.address ? appContext.userInfo.address : '',
			city: appContext.userInfo.city ? appContext.userInfo.city : '',
			state: appContext.userInfo.province ? appContext.userInfo.province : '',
			country: appContext.userInfo.email ? appContext.userInfo.email : '',
			zip: appContext.userInfo.postalCode,
		},
		accounts: {
			bank: 'BCA',
			accountNumber: '6565111111',
			cardNumber: '9498746374847393',
		},
	};

	return <Component {...props} Data={Data} currentUser={currentUser} />;
};
