import { shallow } from 'zustand/shallow';
import { Modal } from '../../../components2/atom/modals/Modal';
import { ModalContent } from '../../../components2/atom/modals/ModalContent';
import { ModalHeader } from '../../../components2/atom/modals/ModalHeader';
import ModalStore from '../../../context/store/modal-pop-up';
import { useEffect, useState } from 'react';
import LanguageHelper from '../../../helpers/l10n.helper';

const AlertModal = () => {
	const { closeModal, mode, type } = ModalStore(state => state, shallow);
	const [lang, setLang] = useState('');

	const t = (key) => LanguageHelper(key, lang === 'id-ID' ? 'id-ID' : 'en-US');

	const isAlert = type === 'alert' && mode === 'alert';

	const closeHandler = () => {
		closeModal();
		if (window) {
			window.close();
		}
	};

	useEffect(() => {
		const language = localStorage.getItem('lang');
		setLang(language);
	}, []);

	const props = {
		openModal: isAlert,
		setOpenModal: closeHandler,
	};

	return (
		<Modal {...props}>
			<ModalHeader {...props} closeButton />
			<ModalContent>
				<p>{t('text.alertLock')}</p>
			</ModalContent>
		</Modal>
	);
};

export { AlertModal };
