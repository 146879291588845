import { forwardRef } from 'react';
import BrandConfigStore from '../../../context/store/brand-config';
import { twMerge } from 'tailwind-merge';

const TextInputForm = (p, ref) => {
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const { startAdornment, endAdornment, className, prefix='id', error, ...rest } = p;
	const { value, onChange } = rest.inputProps;
	const props = {
		...rest,
		labelProps: {
			...p.labelProps,
			className: 'block text-xl mb-2',
			htmlFor: p.name,
			style: { color: colors.primary },
		},
		inputProps: {
			style: { backgroundColor: 'inherit', color: colors.secondary },
			...rest.inputProps,
			...(!ref ? { onChange, value } : { ref }),
			name: p.name,
			id: `${prefix}-${p.name}`,
			className: twMerge('input bg-inherit border-none w-full py-2 px-3 active:ring-0 focus:ring-0', rest.inputProps.className),
		}
	};

	return (
		<div className={twMerge(`space-y-2 border-b ${p.label && 'pt-2'}`, className)} style={{ borderColor: `${error ? 'red' : backgroundColor.secondary}` }}>
			{p.label ? <label {...props.labelProps}>{p.label}</label> : null}
			<div className="flex items-center">
				{startAdornment}
				{props.inputProps.disabled ? <input type="text" className="input bg-inherit border-none w-full py-2 px-3 active:ring-0 focus:ring-0 disabled" placeholder={props.inputProps.placeholder} /> : <input {...props.inputProps} />}
				{endAdornment}
			</div>
		</div>
	);
};

TextInputForm.displayName = 'TextInputForm';

const TextInput = forwardRef(TextInputForm);

export { TextInput };
