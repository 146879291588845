import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../../context/app.context/app.context';
import CurrencyFormatHelper from '../../../helpers/currency-format.helper';
import { useRouter } from 'next/router';
import ChangeCurrencyNotification from './ChangeCurrencyNotificatino';
import SearchIcon from '@mui/icons-material/Search';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';

const ChooseCurrencyModal = (p) => {
	const { setOnClickChooseCurrency } = p;
	const { token, balances, currencySettings } = GlobalStateStore(state => ({ token: state.token, balances: state.balances, currencySettings: state.currencySettings }));

	const [currentCurrency, setCurrentCurrency] = useState([]);
	const [currencyType, setCurrencyType] = useState('');
	const [currencyFiat, setCurrencyFiat] = useState([]);
	const [currencyCrypto, setCurrencyCrypto] = useState([]);
	const [displayCurrencyFiat, setDisplayCurrencyFiat] = useState([]);
	const [displayCurrencyCrypto, setDisplayCurrencyCrypto] = useState([]);
	const [onChangeWallet, setOnChangeWallet] = useState(false);
	const [searchPhrase, setSearchPhrase] = useState('');
	const [openModalNotification, setOpenModalNotification] = useState(false);
	const appContext = useContext(AppContext);
	const router = useRouter();

	const { backgroundColor } = BrandConfigStore(state => state.config.themeConfig.container);

	const { walletBalance } = currencySettings;

	useEffect(() => {
		if (token && !appContext.isLoading) {
			const wallet = balances;
			setCurrencyType(wallet.walletBalance[wallet.activeWallet].currencyType);
			filterCurrency(wallet.walletBalance);
		}
	}, [token, appContext.isLoading]);

	useEffect(() => {
		filterCurrency(balances.walletBalance);
	}, [balances]);

	useEffect(() => {
		if (appContext.refreshPlayPage) {
			if (router.asPath.includes('/play/')) {
				router.reload();
			}
		}
	}, [appContext.refreshPlayPage]);

	useEffect(() => {
		Search();
	}, [searchPhrase, currencyType]);

	const filterCurrency = (balanceList) => {
		const currencyFiatList = [];
		const currencyCryptoList = [];

		Object.keys(balanceList).map(bal => {
			if (p.settings.showFiat) if (balanceList[bal].currencyType == 'fiat') currencyFiatList.push(balanceList[bal]);
			if (balanceList[bal].currencyType == 'crypto') currencyCryptoList.push(balanceList[bal]);
		});

		setDisplayCurrencyFiat(currencyFiatList);
		setDisplayCurrencyCrypto(currencyCryptoList);
		setCurrencyFiat(currencyFiatList);
		setCurrencyCrypto(currencyCryptoList);
	};

	const Search = async () => {
		if (currencyType) {
			if (currencyType.toLowerCase() === 'crypto') {
				const currencyList = currencyCrypto.filter((item) => item.currency.toLowerCase().includes(searchPhrase.toLowerCase()));
				setDisplayCurrencyCrypto(currencyList);
			}
	
			if (currencyType.toLowerCase() === 'fiat') {
				const currencyList = currencyFiat.filter((item) => {
					return item.currency.toLowerCase().includes(searchPhrase.toLowerCase());
				});
				setDisplayCurrencyFiat(currencyList);
			}
		}
	};

	const handleChange = (e) => {
		setSearchPhrase(e.target.value);
	};

	return (
		<>
			<ChangeCurrencyNotification
				{...p}
				openState={openModalNotification}
				setOpenState={setOpenModalNotification}
				wallet={currentCurrency}
				setOnChangeWallet={setOnChangeWallet}
				setOnClickChooseCurrency={setOnClickChooseCurrency}
			/>
			<div className={'relative'}>
				<div
					className="w-screen block absolute z-30 p-[1rem] max-w-[90vw] tablet:max-w-[50vw] laptop:w-[27rem] right-0 top-0 laptop:top-[1.25rem] rounded-[5px] laptop:rounded-[10px]"
					style={{
						background: backgroundColor.tertiary
					}}
				>
					<div className="w-full flex space-x-4 items-center">
						<div className="flex grow bg-[#D7D7D7] text-black my-3 items-center rounded-[5px]">
							<SearchIcon className="ml-2" />
							<input
								className={'balance w-full bg-inherit border-0  border-none focus:ring-0 rounded-[5px]'}
								type="text"
								name="searchPhrase"
								placeholder="Search"
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className={'balance block space-y-2'}>
						<div
							className={'balance join  w-full flex h-[2.5rem] items-center rounded-[5px] bg-opacity-40'}
							// className={'balance w-full flex h-[2.5rem] items-center rounded-[5px] bg-[#d9d9d9] bg-opacity-40'}
						>
							{walletBalance.crypto ? (
								<button
									className={`btn btn-sm join-item flex-1 font-quicksandSemi text-lg ${currencyType === 'crypto' ? 'bg-white text-black' : 'bg-[#d9d9d9] bg-opacity-40 text-white'}`}
									// className={`balance text-center w-full h-full font-quicksandSemi text-lg rounded-lg ${currencyType === 'crypto' ? 'bg-white text-black' : 'bg-[#d9d9d9] bg-opacity-40 text-white'} ${p.settings.showFiat && 'rounded-r-none'}`}
									onClick={() => setCurrencyType('crypto')}
								>
									{appContext.t('text.crypto')}
								</button>
							) : null}
							{walletBalance.fiat && (
								<button
									className={`balance btn btn-sm join-item flex-1 font-quicksandSemi text-lg ${currencyType === 'fiat' ? 'bg-white text-black' : 'bg-[#d9d9d9] bg-opacity-40 text-white'}`} onClick={() => setCurrencyType('fiat')}
									// className={`balance text-center w-full h-full font-quicksandSemi text-lg rounded-r-lg ${currencyType === 'fiat' ? 'bg-white text-black' : 'bg-[#d9d9d9] bg-opacity-40 text-white'}`} onClick={() => setCurrencyType('fiat')}
								>
									{appContext.t('text.fiat')}
								</button>
							)}
						</div>

						<div className={'block'}>
							{currencyType === 'crypto' && walletBalance.crypto && (
								<>
									{displayCurrencyCrypto.map((item) => (
										<React.Fragment key={item.currencyName}>
											<div
												className={`w-full p-1 flex justify-between items-center cursor-pointer ${p?.balances?.walletBalance?.[p?.balances?.activeWallet]?.currencyName === item.currencyName ? 'bg-gray-600 rounded-[5px]' : ''} hover:bg-gray-500 hover:rounded-[5px]`}
												key={item.currencyName}
												onClick={() => {
													if (!onChangeWallet) {
														setOpenModalNotification(true);
														setCurrentCurrency(item);
													}
												}}
											>
												<div
													className={'w-full flex justify-start space-x-4 rounded-[5px] items-center'}
												>
													<div className={'flex items-center'}>
														<img
															className="w-8 h-8"
															src={item.currencyLogo}
														/>
													</div>
													<p
														className={'font-quicksandSemi text-[16px] laptop:text-[18px] text-white uppercase'}
													>
														{item.currency}
													</p>
												</div>
												<div
													className={'font-quicksandSemi text-[16px] laptop:text-[18px]'}
												>
													{CurrencyFormatHelper.currency(
														item.totalBalance
													)}
												</div>
											</div>
										</React.Fragment>
									))}
								</>
							)}
							
							{currencyType === 'fiat' && walletBalance.fiat && (
								<>
									{displayCurrencyFiat.map((item) => (
										<React.Fragment key={item.currencyName}>
											<div
												className={`w-full p-1 flex justify-between items-center cursor-pointer ${p?.balances?.walletBalance?.[p?.balances?.activeWallet]?.currencyName === item.currencyName ? 'bg-gray-600 rounded-[5px]' : ''} hover:bg-gray-500 hover:rounded-[5px]`}
												key={item.currency}
												onClick={() => {
													if (!onChangeWallet) {
														setOpenModalNotification(true);
														setCurrentCurrency(item);
													}
												}}
											>
												<div
													className={'w-full flex justify-start space-x-4 rounded-[5px] items-center'}
												>
													<div className={'flex items-center'}>
														<img
															className="w-8 h-8"
															src={item.currencyLogo}
														/>
													</div>
													<p
														className={'font-quicksandSemi text-[16px] laptop:text-[18px] text-white uppercase'}
													>
														{item.currency}
													</p>
												</div>
												<span
													className={'font-quicksandSemi text-[16px] laptop:text-[18px]'}
												>
													{CurrencyFormatHelper.dollarCurrency(
														item.totalBalance
													)}
												</span>
											</div>
										</React.Fragment>
									))}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ChooseCurrencyModal;
