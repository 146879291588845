import { useContext, useEffect, useState } from 'react';
import { Modal, ModalContent, ModalHeader } from '../../../components2/atom/modals';
import { TextInput } from '../../../components2/molecules/inputs';
import ModalStore from '../../../context/store/modal-pop-up';
import { shallow } from 'zustand/shallow';
import AppContext from '../../../context/app.context/app.context';
import { ShowPasswordButton } from '../../../components2/molecules/buttons';
import BrandConfigStore from '../../../context/store/brand-config';
import AuthServices from '../../../services/api/auth/auth.services';
import GlobalStateStore from '../../../context/store/global-state';
import LoadingCircle from '../Loading/LoadingCircle';
import Validation from '../../../helpers/Validation';

const initShowPassword = {
	newPassword: false,
	confirmPassword: false,
};

const initFormValues = {
	email: '',
	newPassword: '',
	confirmPassword: '',
	otp: '',
};

const ForgotPassword = () => {
	const { brandId, themeConfig } = BrandConfigStore(state => state.config);
	const { backgroundColor, colors } = themeConfig.container;
	const { toast, toastStatus } = GlobalStateStore(state => ({ toast: state.toast, toastStatus: state.toastStatus }));
	const { setModal, closeModal, mode, type } = ModalStore(state => state, shallow);
	const isForgotPassword = mode === 'forgot' && type === 'forgot';
	const appContext = useContext(AppContext);

	const [showPassword, setShowPassword] = useState(initShowPassword);
	const [formValues, setFormValues] = useState(initFormValues);
	const [isClicked, setIsClicked] = useState(false);
	const [isOTPClicked, setIsOTPClicked] = useState(false);
	const [count, setCount] = useState(0);

	const sendOtp = async () => {
		
		const payload = {
			type: 'sendEmailForgetPassword',
			brandId: brandId,
			email: formValues.email,
		};

		try {
			if (!payload.email) throw Error('Please input your email.');
			const res = await AuthServices.ResetPassword(payload);
			if (res.error) throw Error(res.message);
			setIsOTPClicked(true);
			setCount(60);
			toast(toastStatus.SUCCESS, 'Please check your email');
		} catch (error) {
			toast(toastStatus.ERROR, error.message);
		}

	};

	const validation = () => {
		if (
			!Validation.isRequired(formValues.newPassword) ||
      !Validation.isBetween(formValues.newPassword.length, 8, 32) ||
      !Validation.isPasswordSecure(formValues.newPassword)
		) {
			throw Error('The password should contain at least one lowercase letter, one uppercase letter, one numeric, one special character and should be between 8 and 32 characters!');
		}
	};

	const closeModalHandler = () => {
		closeModal();
		setShowPassword(initShowPassword);
		setFormValues(initFormValues);
		setIsOTPClicked(false);
		setCount(0);
	};

	const changeHandler = (e) => {
		const { name, value } = e.target;

		setFormValues(prev => ({ ...prev, [name]: value }));
	};

	const submitHandler = async (e) => {
		e.preventDefault();
		setIsClicked(true);

		const payload = {
			type: 'forgetPassword',
			brandId: brandId,
			email: formValues.email,
			password: formValues.newPassword,
			confirmPassword: formValues.confirmPassword,
			otpCode: formValues.otp,
		};

		try {
			validation();
			const res = await AuthServices.ResetPassword(payload);

			if (res.error) throw Error(res.message);
			toast(toastStatus.SUCCESS, res.data);
			closeModalHandler();
			setModal({ type: 'login', mode: 'login' });
		} catch (error) {
			toast(toastStatus.ERROR, error.message);
		} finally {
			setIsClicked(false);
		}
	};

	useEffect(() => {
		let timer;
		if (count > 0 && isOTPClicked) {
			timer = setInterval(() => {
				if (count > 0) {
					setCount(prev => prev - 1);
				}
			}, 1000);
		}
		
		return () => clearInterval(timer);
	}, [isOTPClicked, count]);

	const p = {
		openModal: isForgotPassword,
		setOpenModal: closeModalHandler,
	};

	const props = {
		emailProps: {
			prefix: 'forgot',
			name: 'email',
			label: appContext.t('label.formEmail'),
			inputProps: {
				type: 'email',
				placeholder: appContext.t('input.emailPlaceholder'),
				value: formValues.email,
				onChange: changeHandler,
			},
		},
		passwordProps: {
			...p,
			prefix: 'forgot',
			name: 'newPassword',
			label: appContext.t('label.newPassword'),
			inputProps: {
				type: showPassword.newPassword ? 'text' : 'password',
				placeholder: appContext.t('input.newPasswordPlaceholder'),
				value: formValues.newPassword,
				onChange: changeHandler,
				disabled: !isOTPClicked,
			},
			endAdornment: <ShowPasswordButton
				name="current"
				showPassword={showPassword.newPassword}
				setShowPassword={(val) => setShowPassword(prev => ({ ...prev, newPassword: val }))}
			/>
		},
		confirmPasswordProps: {
			...p,
			prefix: 'forgot',
			name: 'confirmPassword',
			label: appContext.t('label.confirmPassword'),
			inputProps: {
				type: (showPassword.confirmPassword ? 'text' : 'password'),
				placeholder: appContext.t('input.passwordPlaceholder'),
				value: formValues.confirmPassword,
				onChange: changeHandler,
				disabled: !isOTPClicked,
			},
			endAdornment: <ShowPasswordButton
				name="current"
				showPassword={showPassword.confirmPassword}
				setShowPassword={(val) => setShowPassword(prev => ({ ...prev, confirmPassword: val }))}
			/>
		},
		otpProps: {
			...p,
			prefix: 'forgot',
			name: 'otp',
			label: appContext.t('label.otp'),
			inputProps: {
				placeholder: appContext.t('input.otp'),
				value: formValues.otp,
				onChange: changeHandler,
			},
			endAdornment: (
				<button
					type="button"
					onClick={(e) => {
						e.preventDefault();
						sendOtp();
					}}
					className={`btn btn-sm ${count <= 0 ? 'btn-ghost' : 'btn-disabled'} w-fit`}
					{...(count > 0 && {style: { backgroundColor: 'inherit', color: 'GrayText' }})}
				>{count > 0 ? count : appContext.t('btn.otp')}</button>
			)
		},
	};

	return (
		<Modal {...p} >
			<ModalHeader {...p} closeButton />
			<ModalContent>
				<form onSubmit={submitHandler} className="space-y-5 ">
					<TextInput {...props.emailProps} />
					<TextInput {...props.passwordProps} />
					<TextInput {...props.confirmPasswordProps} className="placeholder-slate-200::placeholder" />
					<TextInput {...props.otpProps} />

					<button
						className="py-2.5 rounded-full w-full"
						disabled={!isOTPClicked || isClicked || !formValues.otp.length}
						type='submit'
						style={{
							backgroundColor: backgroundColor.accent2,
							filter: (!isOTPClicked || isClicked || !formValues.otp.length) && 'brightness(50%)',
							color: colors.accent
						}}
					>
						{isClicked ? <LoadingCircle bodyClassName="h-4" /> : appContext.t('btn.submit')}
					</button>
				</form>
			</ModalContent>
		</Modal>
	);
};

export default ForgotPassword;
