import { useTransition, animated } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import BrandConfigStore from '../../../context/store/brand-config';

const Modal = (p) => {
	const { openModal } = p;
	const refBody = useRef(null);

	useEffect(() => {
		refBody.current = document.querySelector('body');
	}, [openModal]);

	return refBody.current && createPortal(<ModalContainer {...p} />, refBody.current);
};

const ModalContainer = (p) => {
	const { openModal } = p;
	
	const transition = useTransition(openModal, {
		from: {
			opacity: 0,
		},
		enter: {
			position: 'fixed',
			zIndex: 500,
			backgroundColor: 'rgba(0,0,0,.3)',
			inset: '0px',
			height: '100dvh',
			opacity: 1,
			backdropFilter: 'blur(10px)',
			WebkitBackdropFilter: 'blur(10px)',
		},
		leave: {
			opacity: 0,
		},
	});

	return (
		transition((style, item) => item ? (
			<animated.div style={style} >
				<div className="relative max-w-md mx-auto h-full p-4">
					<Boxx {...p} />
				</div>
			</animated.div>
		) : null)
	);
};

const Boxx = (p) => {
	const { children, openModal } = p;
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const dialogRef = useRef(null);

	useEffect(() => {
		if (openModal) {
			dialogRef.current.show();
			document.body.classList.add('overflow-hidden');
			document.body.classList.add('relative');
			document.body.classList.add('h-screen');
		} else {
			document.body.classList.remove('overflow-hidden');
			document.body.classList.remove('relative');
			document.body.classList.remove('h-screen');
		}
	}, [openModal]);

	return (
		<dialog
			style={{ backgroundColor: backgroundColor.tertiary, boxShadow: `0 2px 30px -20px ${colors.primary}` }} 
			ref={dialogRef} className="space-y-3 w-full max-w-md max-h-full overflow-auto relative top-1/2 -translate-y-1/2 rounded-md p-4 transition-all duration-500">
			{children}	
		</dialog>
	);
};

export { Modal };
