import React, { useContext } from 'react';
import BrandConfigStore from '../../../context/store/brand-config';
import AppContext from '../../../context/app.context/app.context';
import Link from 'next/link';

function SocialMediaFooter({ type }) {
	const {
		config: { themeConfig, footer }
	} = BrandConfigStore((state) => ({ config: state.config }));

	const { colors } = themeConfig.footer;
	const appContext = useContext(AppContext);

	const socialMediaContact =
		footer.contact.filter((contact) => contact.type === type)[0]?.option ?? [];

	const sortedSocialMediaContact = [...socialMediaContact].sort((a, b) => a.order - b.order);

	// If no social media contact object is found, return null or an appropriate message
	// if (!socialMediaContact) return <p>No social media contacts found.</p>;

	return (
		<React.Fragment>
			<h2
				className="text-base font-semibold"
				style={{
					color: colors.secondary
				}}>
				{appContext.t('h2.contactUs')}
			</h2>
			<div
				className="text-base space-y-2 mt-2"
				style={{
					color: colors.primary
				}}>
				{sortedSocialMediaContact.map((media, index) => (
					<React.Fragment key={index}>
						{media.isDisplay && (
							<Link href={media.url}>
								<a target="_blank" rel="noopener noreferrer" className="cursor-pointer block">
									{media.item}
								</a>
							</Link>
						)}
					</React.Fragment>
				))}
			</div>
		</React.Fragment>
	);
}

export default SocialMediaFooter;
