import { shallow } from 'zustand/shallow';
import { Modal } from '../../../components2/atom/modals/Modal';
import { ModalContent } from '../../../components2/atom/modals/ModalContent';
import { ModalHeader } from '../../../components2/atom/modals/ModalHeader';
import ModalStore from '../../../context/store/modal-pop-up';
import BrandConfigStore from '../../../context/store/brand-config';
import { useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';

const LanguageModal = () => {
	const { closeModal, mode, type } = ModalStore(state => state, shallow);
	const { languages } = BrandConfigStore(state => ({ languages: state.languages }));

	const isLanguage = mode === 'language' && type === 'language';

	const [selected, setSelected] = useState({
		lang: 'English',
		langCode: 'en-US',
	});

	const closeModalHandler = () => {
		closeModal();
	};

	const changeLanguage = (idx) => {
		if (selected.langCode !== languages[idx].langCode) {
			localStorage.setItem('lang', languages[idx].langCode);
			location.reload();
		} 
		return closeModal();
	};

	useEffect(() => {
		languages?.map(languageInfo => {
			if (localStorage.getItem('lang') == languageInfo.langCode) setSelected(languageInfo);
		});
	}, [languages]);

	const statePop = {
		openModal: isLanguage,
		setOpenModal: closeModalHandler,
	};

	return (
		<Modal {...statePop}>
			<ModalHeader {...statePop} closeButton />
			<ModalContent>
				<ul>
					{languages.map((lang, idx) => (
						<li key={'language-' + lang.langCode}
							onClick={() => changeLanguage(idx)}
							// className={`cursor-pointer p-3 flex items-center gap-3 ${idx != 0 ? 'pt-0' : ''}`}>
							className={'cursor-pointer p-3 flex items-center gap-3'}>
							<p className="whitespace-nowrap" >{lang.lang}</p>
							{lang.langCode === selected.langCode ? <AiOutlineCheck /> : null}
						</li>
					))}
				</ul>
			</ModalContent>
		</Modal>
	);
};

export { LanguageModal };
