import { useEffect, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import BrandConfigStore from '../../../context/store/brand-config';
import { AiOutlineCheck } from 'react-icons/ai';

const LanguageDropdown = () => {
	const { config, languages } = BrandConfigStore(state => ({ config: state.config, languages: state.languages }));
	const { backgroundColor, colors } = config.themeConfig.container;
	
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState({
		lang: 'English',
		langCode: 'en-US',
	});
	useEffect(() => {
		languages?.map(languageInfo => {
			if (localStorage.getItem('lang') == languageInfo.langCode) setSelected(languageInfo);
		});
	}, [languages]);

	const changeLanguage = (idx) => {
		if (selected.langCode === languages[idx].langCode) return setIsOpen(false);
		localStorage.setItem('lang', languages[idx].langCode);
		setIsOpen(false);
		location.reload();
	};

	const dropdownItems = languages?.map((item, idx) => {
		return (
			<li key={'language-' + item.langCode} onClick={() => changeLanguage(idx)} className={`cursor-pointer p-3 flex items-center gap-3 ${idx != 0 ? 'pt-0' : ''}`}>
				<p className="whitespace-nowrap" >{item.lang}</p>
				{item.langCode === selected.langCode ? <AiOutlineCheck /> : null}
			</li>
		);
	});

	return (
		<details className="dropdown dropdown-end">
			<summary tabIndex={0} className="btn m-1 bg-inherit hover:bg-inherit border-none" style={{ color: colors.primary }} onClick={() => setIsOpen(!isOpen)}>
				<LanguageIcon />
			</summary>
			
			<ul
				tabIndex={0}
				className="dropdown-content z-[100] max-h-[30rem] p-4 flex-row scrollbar-hide overflow-y-auto rounded-lg"
				// className="dropdown-selector absolute right-0 mt-3 w-44 rounded-lg shadow-xl"
				style={{
					background: backgroundColor.secondary,
					color: colors.primary
				}}
			>
				{dropdownItems}
			</ul>
		</details>
	);
};

export default LanguageDropdown;
