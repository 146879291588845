import axios from 'axios';
import getAffiliateToken from '../../getAffiliateToken';

const GetAfilliateList = (payload) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/referral/playerList`, payload);
};

const GetSubAffiliateList = (payload) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/referral/subAffiliateList`, payload);
};

const GetProfile = (payload) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/auth/me`, payload);
};

const RefferalCreate = (payload) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/referral/create`, payload);
};

const Register = (payload) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/auth/register`, payload);
};

const CreateReferral = (referralCode) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/referral/create`, { referralCode });
};

const UpdateProfile = (payload) => {
	axios.defaults.headers.common.Authorization = getAffiliateToken();
	return axios.post(`${process.env.AFF_URL}/v1/auth/updateProfile`, payload);
};

export default {
	GetAfilliateList,
	GetSubAffiliateList,
	GetProfile,
	RefferalCreate,
	Register,
	CreateReferral,
	UpdateProfile
};
