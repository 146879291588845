// import axios from 'axios';
import BrandSetting from '../public/brandSettings.json';
import newPresets from '../public/data/newPresets.json';

// export async function GetBrandSetting(brandId) {
// 	return axios.post(`${process.env.BASE_URL}/v1/getBrandSetting`, { brandId })
// 		.then(res => res.data)
// 		.catch(error => {
// 			throw error;
// 		});
// }

export async function GetBrandSetting() {
	return new Promise((resolve) => {
		resolve(BrandSetting);
	});
}

export async function GetProviders() {
	return new Promise((resolve) => {
		resolve(newPresets);
	});
}
