import { useContext, useState } from 'react';
import UserServices from '../../../services/api/user/user.services';
import LoadingCircle from '../Loading/LoadingCircle';
import OutsideAlerter from '../OutsideClick/OutsideClick';
import GlobalStateStore from '../../../context/store/global-state';
import AppContext from '../../../context/app.context/app.context';

const ChangeCurrencyNotification = (p) => {
	const { wallet, openState, setOpenState, setOnChangeWallet, setOnClickChooseCurrency } = p;

	const [disableButton, setDisableButton] = useState(false);
	const { toast, toastStatus, token, setBalances, balances } = GlobalStateStore((state) => ({
		toast: state.toast,
		toastStatus: state.toastStatus,
		token: state.token,
		setBalances: state.setBalances,
		balances: state.balances
	}));
	const appContext = useContext(AppContext);

	const changeMainWallet = async () => {
		try {
			setDisableButton(true);
			setOnChangeWallet(true);
			if (wallet) {
				const changeMainWallet = await UserServices.changeMainWallet2({
					token: token,
					currencyName: wallet.currency
				});

				if (changeMainWallet.data) {
					const oldBal = balances;
					const newBal = {
						...oldBal,
						activeWallet: wallet.currency
					};
					localStorage.setItem('balances', JSON.stringify(newBal));
					// setBalances(JSON.parse(localStorage.getItem('balances')));
					setBalances(newBal);
					sendMessage();
				} else if (changeMainWallet.error !== 0) {
					toast(toastStatus.ERROR, changeMainWallet.message);
				}
			}
			setDisableButton(false);
			setOnChangeWallet(false);
			setOnClickChooseCurrency(false);
		} catch (error) {
			setDisableButton(false);
			setOnChangeWallet(false);
			setOnClickChooseCurrency(false);
		}
	};

	const onClickNo = () => {
		setOpenState(false);
		setOnClickChooseCurrency(false);
	};

	const onClickYes = () => {
		setDisableButton(true);
		changeMainWallet();
	};

	const sendMessage = () => {
		const channel = new BroadcastChannel('my_channel');
		channel.postMessage('changeWalletBroadcast');
		channel.close();
	};

	return (
		<>
			{openState && (
				<>
					<div
						className={'absolute w-screen h-screen left-0 inset-0 z-[60] text-white'}
						style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
						<div
							className={
								'flex overflow-y-auto overflow-x-hidden w-full z-[100] inset-0 h-modal h-full justify-center items-center'
							}>
							<OutsideAlerter state={setOpenState}>
								<div className={'relative p-4 w-[20rem] laptop:w-[30rem] h-full md:h-auto'}>
									<div
										className={
											'block relative w-full bg-[#00111D] p-4 space-y-4 rounded-lg shadow laptop:space-y-[3rem]'
										}>
										<div className={'text-center font-quicksandBold'}>
											{appContext.t('text.currencyChangeConfirm')}
										</div>
										<div className={'grid grid-cols-2 gap-4'}>
											<button
												type="button"
												className={'bg-red-600 rounded-[5px] py-2 hover:bg-[]'}
												onClick={() => {
													onClickNo();
												}}>
												{appContext.t('btn.no')}
											</button>
											<button
												type="button"
												className={'bg-green-600 rounded-[5px]'}
												onClick={() => {
													onClickYes();
												}}
												disabled={disableButton}>
												{disableButton ? (
													<>
														<LoadingCircle addClassName={'w-[20px] h-[20px]'} />
													</>
												) : (
													appContext.t('btn.yes')
												)}
											</button>
										</div>
									</div>
								</div>
							</OutsideAlerter>
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default ChangeCurrencyNotification;
