import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import BrandConfigStore from '../../../context/store/brand-config';

const Input = (p) => {
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const { value, setValue, number, formatNumber, accountNumber,	startAdornment, endAdornment, className, style, name, ...rest } = p;
	const [shownValue, setShownValue] = useState(value);

	useEffect(() => {
		if (!setValue) return;
		if (value) {
			if (number) setShownValue(value.replace?.(/\D/g, ''));
			if (number && formatNumber) {
				let newValue = value;
				if (value.toString().slice(-1) === '.' || value.toString().slice(-1) === '0') newValue = value;
				const newFormatNumber = newValue.toLocaleString('en-US');
				if (accountNumber) {
					const account = newFormatNumber.replace(/,/g, '-');
					setShownValue(account);
				} else {
					setShownValue(newFormatNumber);
				}

			}
			else setShownValue(value);
		} else {
			// if (number) setShownValue('');
			if (!value && number) setShownValue('');
			else setShownValue(value);
		}
	}, [value]);

	useEffect(() => {
		if (!setValue) return;

		if (shownValue && number && formatNumber && (shownValue.toString().slice(-1) !== '.' && shownValue.toString().slice(-1) !== '0')) {
			if (isNaN(parseFloat(shownValue.toString()))) return;
			setValue(parseFloat(shownValue.toString().replaceAll(',', '')));

		} else if (!shownValue?.toString().includes('.') && shownValue && number) {
			setValue(parseFloat(shownValue.toString().replaceAll(',', '')));
		} else {
			setValue(shownValue);
		}
	}, [shownValue]);

	return (
		<div className={twMerge('join w-full', className)} style={style ?? { backgroundColor: backgroundColor.tertiary, color: colors.primary, borderColor: backgroundColor.secondary }}>
			{startAdornment ? (
				<div className="whitespace-nowrap flex items-center px-1 join-item" style={{ filter: 'brightness(150%)' }}>
					{startAdornment}
				</div>
			) : null}
			<input
				className='input join-item bg-inherit input-xst w-full max-w-xs outline-none focus:outline-none active:outline-none ring-0 focus:ring-0 active:ring-0 border-0'
				value={shownValue}
				name={name}
				onChange={e => setValue(e.target.value)}
				{...rest}
			/>
			{endAdornment ? (
				<div className="whitespace-nowrap flex items-center px-2 join-item bg-red-300">
					{endAdornment}
				</div>
			) : null}
		</div>
	);
};

export { Input };
