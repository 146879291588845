import Image from 'next/image';
import { useRouter } from 'next/router';
import customLoader from '../../../helpers/custom-image-loader';
import BrandConfigStore from '../../../context/store/brand-config';
import CustomString from '../../../helpers/custom-string.helper';

function NavItems({ title, icon, url }) {
	const router = useRouter();
	const { colors } = BrandConfigStore((state) => state.config.themeConfig.navbar);

	return (
		<div className="flex flex-col justify-center items-center cursor-pointer group sm:w-auto flex-shrink">
			<div className="relative w-6 aspect-square md:hidden mb-3 fill-white">
				<Image src={icon} loader={customLoader} layout="fill" objectFit="contain" />
			</div>

			{/* <Link href={}></Link> */}
			<p
				className={`${
					router.asPath == '/#sports' || router.asPath == '/#lottery'
						? ''
						: router.asPath == url
							? 'border-b'
							: ''
				}  text-xxs xl:text-base  tracking-wider text-center whitespace-nowrap`}
				style={{
					color: colors.primary
				}}>
				{CustomString.capitalizeFirstLetter(title)}
			</p>
		</div>
	);
}
export default NavItems;
