import axios from 'axios';
import getToken from '../../getToken';
import { fetchData, fetchDataAuth } from '../fetchData';

const Login = (payload, authToken) => {
	return axios
		.post(`${process.env.BASE_URL}/v1/login`, payload, {
			headers: {
				Authorization: `${payload.brandId.length}${payload.brandId}${authToken}`
			}
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const Login3 = (payload, fingerprint, authToken) => {
	return axios
		.post(`${process.env.BASE_URL}/v1/login3`, payload, {
			headers: {
				Authorization: `${authToken}`
				// 'Client-Fingerprint': fingerprint
			}
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const Logout = () => {
	return fetchData(null, 'logout')
		.then((response) => response.data)
		.catch((error) => {
			throw error.data;
		});
};

const Register = async (payload, authToken) => {
	const payloadData = {};
	for (const data in payload) {
		payload[data] && data !== 'termsConditions' && (payloadData[data] = payload[data]);
	}
	return axios
		.post(`${process.env.BASE_URL}/v1/register`, payloadData, {
			headers: {
				Authorization: `${payload.brandId.length}${payload.brandId}${authToken}`
			}
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error;
		});
};

const Register2 = async (payload, authToken) => {
	const tokenAuth = `${payload.brandId.length}${payload.brandId}${authToken}`;
	try {
		const res = await fetchDataAuth(payload, tokenAuth, 'register2');
		return res;
	} catch (error) {
		throw error.data;
	}
};

const Register3 = async (payload, authToken) => {
	const tokenAuth = `${authToken}`;
	try {
		const res = await fetchDataAuth(payload, tokenAuth, 'register3');
		return res;
	} catch (error) {
		throw error.data;
	}
};

const ResetPassword = (payload) => {
	axios.defaults.headers.common.Authorization = getToken();
	return axios
		.post(`${process.env.BASE_URL}/v1/resetPassword`, payload)
		.then((response) => response.data)
		.catch((error) => {
			throw error.response.data;
		});
};

// new session token
const TsGetSessionToken = ({ tsToken, fingerprint, brandId }) => {
	return axios
		.post(
			`${process.env.BASE_URL}/v1/tsGenerateSessionToken`,
			{ brandId: brandId, clientFingerprint: fingerprint },
			{
				headers: {
					Authorization: tsToken
				}
			}
		)
		.then((response) => response?.data)
		.catch((error) => {
			throw error.response.data;
		});
};

const TsSessionTokenAuthorizer = ({ brandId, fingerprint, sessionToken }) => {
	const newSessionToken = `${brandId.length}${brandId}${sessionToken}`;

	return axios
		.post(
			`${process.env.BASE_URL}/v1/tsSessionTokenAuthorizer`,
			{ clientFingerprint: fingerprint },
			{
				headers: {
					Authorization: newSessionToken
				}
			}
		)
		.then((response) => response?.data)
		.catch((error) => {
			throw error.response.data;
		});
};

const GetSessionToken = ({ tsToken, fingerprint, brandId }) => {
	return axios
		.post(
			`${process.env.BASE_URL}/v1/generateSessionToken`,
			{ brandId: brandId, fingerprint: fingerprint },
			{
				headers: {
					Authorization: tsToken
				}
			}
		)
		.then((response) => response?.data)
		.catch((error) => {
			throw error.response.data;
		});
};

const VerifyAccount = (payload) => {
	return axios
		.post(`${process.env.BASE_URL}/v1/verifyAccountData`, payload, {
			headers: {
				Authorization: getToken()
			}
		})
		.then((response) => response.data)
		.catch((error) => {
			throw error.response.data;
		});
};

const AuthServices = {
	Login,
	Login3,
	Logout,
	Register,
	Register2,
	Register3,
	ResetPassword,
	GetSessionToken,
	TsGetSessionToken,
	TsSessionTokenAuthorizer,
	VerifyAccount
};

export default AuthServices;
