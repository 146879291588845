import Link from 'next/link';
import { useContext, useEffect, useState } from 'react';
import BrandArticles from '../../../../public/brandArticles.json';
import BrandConfigStore from '../../../context/store/brand-config';
import AppContext from '../../../context/app.context/app.context';
import { withData } from '../../../resources/hoc/withData';
import GlobalStateStore from '../../../context/store/global-state';
import { useRouter } from 'next/router';
import SocialMediaFooter from '../../molecules/SocialMediaFooter';

const logos = {
	coins: ['btc', 'bsc', 'doge', 'eth', 'shib', 'usdt'],
	prov: ['metamask', 'trustwallet', 'walletconnect', 'coinbase', 'mastercard', 'visa']
};

function Footer() {
	const [promos, setPromos] = useState([]);
	const {
		config: { themeConfig, brandName, brandLogo, navigation }
	} = BrandConfigStore((state) => ({ config: state.config }));
	const { backgroundColor, colors } = themeConfig.footer;
	const { setUrl, headerHeight } = GlobalStateStore((state) => ({
		setUrl: state.setUrl,
		headerHeight: state.headerHeight
	}));
	const appContext = useContext(AppContext);
	const articles = BrandArticles.data.footer;
	const router = useRouter();

	const handleClick = (id) => {
		if (id.includes('#')) {
			if (router.pathname !== '/') {
				setUrl(id);
				router.push('/');
			}

			const elm = document.querySelector(id);
			window.scrollTo(0, elm?.getBoundingClientRect().top + window.scrollY - headerHeight);
		} else {
			router.push(`/${id}`);
		}
	};

	const sortArr = (a, b) => (a.order > b.order ? 1 : -1);

	useEffect(() => {
		const article = articles?.slice(0, 10).filter((article) => !!article.isPublish);
		setPromos(article);
	}, [articles]);

	return (
		<div
			className="w-full font-robotoRegular "
			style={{
				background: backgroundColor.primary,
				color: colors.primary
			}}>
			<div className="myContainer mx-auto">
				<div className="">
					<div className="py-4">
						<div>
							<div className="grid grid-cols-2 mobile-xl:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-x-4 gap-y-0">
								{logos.coins.map((c) => (
									<img
										key={c}
										src={`/images/Footer2/${c}.svg`}
										alt={c}
										className="w-11/12 footerIcon mx-auto"
									/>
								))}
							</div>
						</div>
						<div>
							<div
								className="h-0.5 col-span-8 "
								style={{
									background: colors.secondary,
									opacity: 0.2
								}}></div>
							<div className="grid gap-x-4 gap-y-0 grid-cols-2 mobile-xl:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
								{logos.prov.map((lg) => (
									<img
										key={lg}
										src={`/images/Footer2/${lg}.svg`}
										alt={lg}
										className="footerIcon w-11/12 mx-auto"
									/>
								))}
							</div>
						</div>
					</div>
					<div
						className="h-0.5"
						style={{
							background: colors.primary,
							opacity: 0.35
						}}></div>
					<div className="my-8">
						<div className="grid grid-cols-2 mobile-l:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 ">
							<div className="col-span-1">
								<h2
									className="text-base font-semibold"
									style={{
										color: colors.secondary
									}}>
									{appContext.t('h2.ourGames')}
								</h2>
								<div
									className="text-base justify-start items-start flex flex-col mt-2"
									style={{
										color: colors.primary
									}}>
									{navigation.sideBar.sort(sortArr).map((item) =>
										item.enabled ? (
											<button onClick={() => handleClick(item.page.slice(1))} key={item.name}>
												{appContext.t(item.name)}
											</button>
										) : null
									)}
								</div>
							</div>
							<div className="col-span-1">
								<SocialMediaFooter type={'social media'} />
							</div>
							<div className="col-span-1">
								<h2
									className="text-base font-semibold"
									style={{
										color: colors.secondary
									}}>
									{appContext.t('h2.aboutUs')}
								</h2>
								<div
									className="text-base space-y-2 mt-2"
									style={{
										color: colors.primary
									}}>
									{/* <Link href="/">
										<a className="cursor-pointer block">{appContext.t('text.termsConditions')}</a>
									</Link> */}
									<Link href="/faq">
										<a className="cursor-pointer block">FAQ</a>
									</Link>
									{/* <Link href="/affiliate/login">
										<a className="cursor-pointer block">Affiliate</a>
									</Link> */}
								</div>
							</div>
							<div
								className="h-0.5 col-span-full lg:hidden"
								style={{
									background: colors.secondary,
									opacity: 0.2
								}}></div>
							<div className="col-span-2 mobile-l:col-span-3 mobile-xl:col-span-4 lg:col-span-3">
								<h2
									className="text-base font-semibold"
									style={{
										color: colors.secondary
									}}>
									{appContext.t('h2.article')}
								</h2>
								<div
									className="text-base text-th-main-text grid-cols-2 grid mobile-l:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3 mt-2 "
									style={{
										color: colors.primary
									}}>
									{promos?.map((unit, index) => (
										<div key={index} className="mb-2">
											<a href={`/a/${unit.id.toLowerCase()}.html`} className="cursor-pointer">
												{unit.title}
											</a>
										</div>
									))}
								</div>
							</div>
						</div>
					</div>

					<div
						className="h-0.5"
						style={{
							background: colors.primary,
							opacity: 0.35
						}}></div>
					<div className="py-8">
						<div className="gap-4 pb-24 mobile-xl:pb-0 flex flex-col md:flex-row md:items-center justify-center">
							{brandLogo ? (
								<img
									src={brandLogo}
									className="logoIcon w-4/12 sm:w-3/12 md:w-2/12 h-full object-contain"
								/>
							) : (
								<h1 className="xl:text-4xl">{brandName}</h1>
							)}
							<div className="flex items-center">
								<div className="space-y-2">
									<p
										className="text-sm "
										style={{
											color: colors.primary
										}}>
										{' '}
										© {new Date().getFullYear()} {brandName}.{' '}
										{appContext.t('text.allRightsReserved')} | 18+
									</p>
									<p
										className="text-sm "
										style={{
											color: colors.secondary
										}}>
										{brandName} {appContext.t('text.footerText')}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default withData(Footer);
