import { useEffect, useState } from 'react';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import Head from 'next/head';
import { API, Amplify } from 'aws-amplify';
import config from '../src/constants/config';
import localStorage from 'localStorage';
import { ToastContainer, toast } from 'react-toastify';
import { settings } from '../settings';
import BrandSetting from '../public/brandSettings.json';
import FingerprintHelper from '../src/helpers/fingerprint.helper';
import LanguageHelper from '../src/helpers/l10n.helper';
import AppContextProvider from '../src/context/app.context/app.context.provider';
import Layout from '../src/components/templates/Layout/Layout';
import CFTurnstile from '../src/components/atom/Turnstile';
import '../styles/globals.scss';
import 'react-toastify/dist/ReactToastify.css';
import GameServices from '../src/services/api/games/game.services';
import LayoutAffiliate from '../src/components/templates/Layout/LayoutAffiliate';
import BrandConfigStore from '../src/context/store/brand-config';
import CountLogin from '../src/graphql/subscriptions/count-login';
import GlobalStateStore from '../src/context/store/global-state';
import ModalStore from '../src/context/store/modal-pop-up';
import ConstantDataStore from '../src/context/store/constant-data';
import usePersistentState from '../src/hooks/usePersistentState';

Amplify.configure(config.aws_amplify);

const MyApp = ({ Component, pageProps }) => {
	const router = useRouter();

	const { setPersistentState } = usePersistentState('', 'referralCode');
	const [globalLoading, setGlobalLoading] = useState(true);
	const [updatingBalance, setUpdatingBalance] = useState(true);
	const [selectedLanguage, setSelectedLanguage] = useState('en-US');
	const [dangerLog, setDangerLog] = useState(false);
	const [isSessionVerified, setIsSessionVerified] = useState(false);
	const theme = parseInt(BrandSetting?.layoutVersion ?? 1);
	const [languages] = useState([{ langCode: 'en-US', lang: 'English' }]); //remove later

	const { brandConfig, isLoadingConfig } = BrandConfigStore((state) => ({
		brandConfig: state.config,
		isLoadingConfig: state.isLoadingConfig
	}));
	const { token, fingerprint, setFingerprint } = GlobalStateStore((state) => ({
		token: state.token,
		setReferralCode: state.setReferralCode,
		fingerprint: state.fingerprint,
		setFingerprint: state.setFingerprint
	}));

	const { getCurrencyList, getProviders, getBankList } = ConstantDataStore((state) => ({
		getCurrencyList: state.getCurrencyList,
		getProviders: state.getProviders,
		getBankList: state.getBankList
	}));

	const setModal = ModalStore((state) => state.setModal);

	const t = (key) => LanguageHelper(key, selectedLanguage);

	const storePathValues = () => {
		const storage = globalThis?.sessionStorage;
		if (!storage) return;
		const prevPath = storage.getItem('currentPath');
		storage.setItem('prevPath', prevPath ? prevPath : '/');
		storage.setItem('currentPath', globalThis.location.pathname);
	};

	useEffect(() => {
		if (!window) return;
		if (localStorage.getItem('lang') == null) localStorage.setItem('lang', 'id-ID');
		setSelectedLanguage(localStorage.getItem('lang'));
	}, []);

	useEffect(() => {
		const isRegister = 'register' in router.query;
		const isLogin = token && token?.split(':')[0].toLowerCase() !== 'sessiontoken';
		if (!isLogin && router.asPath.includes('account')) window.location = '/';
		if (isRegister && router.query.register && !isLogin) {
			const code = router.query.referralCode;
			setModal({ mode: 'register', type: 'register' });

			if (code) {
				setPersistentState(code);
			}

			delete router.query;
			router.push(router);
		} else if (isRegister && router.query.register && isLogin) {
			delete router.query;
			router.push(router);
		}

		GameServices.GetGame({ limit: 1 })
			.then(() => {
				setIsSessionVerified(true);
				// setIsSessionError(false);
			})
			.catch(() => {
				setIsSessionVerified(false);
				// setIsSessionError(true);
				localStorage.removeItem('token');
				localStorage.removeItem('sessionToken');
			});
	}, [router]);

	useEffect(() => storePathValues(), [router.asPath]);

	useEffect(() => {
		FingerprintHelper.GenerateFingerprint([
			'userAgent',
			'language',
			'colorDepth',
			'cookieEnabled',
			'deviceMemory',
			'doNotTrack',
			'screenResolution',
			'vendor'
		]).then(setFingerprint);
	}, []);
	// Remove later
	useEffect(() => {
		const handlePostMessage = (event) => {
			if (event.data?.name === 'gameQuit') {
				window.location.href = brandConfig.websiteUrl;
			}
		};

		window.addEventListener('message', handlePostMessage);

		return () => {
			window.removeEventListener('message', handlePostMessage);
		};
	}, []);

	// end

	useEffect(() => {
		const loader = document.getElementById('globalLoader');
		if (globalLoading) loader.style.opacity = '1';
		else {
			loader.style.opacity = '0';
			loader.style.pointerEvents = 'none';
		}
		setTimeout(() => {
			setGlobalLoading(false);
		}, 0);
	}, [globalLoading]);

	// google tag manager brand

	// useEffect(() => {
	// 	window.dataLayer = window.dataLayer || [];
	// 	window.dataLayer.push({
	// 		brandId: BrandSetting.brandId
	// 	});
	// }, []);

	Router.onRouteChangeStart = () => {
		setGlobalLoading(true);
		if (typeof window !== 'undefined') window.scrollTo(0, 0);
	};

	Router.onRouteChangeComplete = () => {
		setGlobalLoading(false);
	};

	Router.onRouteChangeError = () => {
		setGlobalLoading(false);
	};

	useEffect(() => {
		getCurrencyList({ type: 'get', currencyType: 'all', brandId: BrandSetting.brandId });
		getProviders();
		getBankList();
		if (token && token?.split(':')[0].toLowerCase() !== 'sessiontoken') {
			API.graphql({
				query: CountLogin.mutationLogin,
				authToken: token
			});
		} else {
			localStorage.removeItem('balances');
		}
	}, [token]);

	// eslint-disable-next-line no-const-assign
	pageProps = {
		...pageProps,
		brandConfig, //remove later
		LanguageHelper,
		selectedLanguage,
		setSelectedLanguage,
		languages, // remove later
		updatingBalance,
		setUpdatingBalance,
		globalLoading,
		setGlobalLoading,
		brandId: BrandSetting.brandId,
		isLoadingConfig, // remove later
		isSessionVerified,
		setIsSessionVerified,
		// setIsSessionError,
		settings,
		dangerLog,
		setDangerLog,
		toast,
		theme,
		t
	};

	return (
		<>
			<AppContextProvider>
				<CFTurnstile />
				<ToastContainer
					position="top-right"
					autoClose={1000 * 5}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable={false}
					pauseOnHover={false}
				/>
				{router.pathname.startsWith('/affiliate') ? (
					<LayoutAffiliate {...pageProps}>
						<Component {...pageProps} key={router} />
					</LayoutAffiliate>
				) : (
					<Layout {...pageProps}>
						{!isLoadingConfig && (
							<Head>
								<link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
								<meta name="viewport" content="width=device-width, initial-scale=1" />
								<title>{brandConfig.websiteTitle}</title>
								<meta name="robots" content="/sitemap.xml" />
								<meta content={brandConfig.websiteDescription} name="description" />
								<link rel="canonical" href={brandConfig.websiteUrl} />
								{/* Open Graph */}
								<meta property="og:type" content="website" />
								<meta property="og:site_name" content={brandConfig.brandName} />
								<meta property="og:description" content={brandConfig.websiteDescription} />
								<meta property="og:title" content={brandConfig.websiteTitle} />
								<meta property="og:url" content={brandConfig.websiteUrl} />
								<meta property="og:image" name="image" content={brandConfig.brandLogo} />
								{/* Twitter */}
								<meta name="twitter:card" content="summary_large_image" />
								{/* <meta name="twitter:site" content="" /> */}
								<meta name="twitter:title" content={brandConfig.websiteTitle} />
								<meta name="twitter:description" content={brandConfig.websiteDescription} />
								<meta name="twitter:image" content={brandConfig.brandLogo} />
								<meta
									name="msapplication-TileColor"
									content={brandConfig.themeConfig.container.backgroundColor.primary}
								/>
								<meta
									name="theme-color"
									content={brandConfig.themeConfig.container.backgroundColor.primary}
								/>
								<script
									dangerouslySetInnerHTML={{
										__html: `
										(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
										new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
										j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
										'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
										})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`
									}}
								/>
							</Head>
						)}
						{brandConfig.script && token && !token.toLowerCase().includes('sessiontoken') && (
							<>
								<Script id="google-tag-manager" strategy="afterInteractive">
									{brandConfig.script}
								</Script>
								<noscript>
									<iframe
										src="https://www.googletagmanager.com/ns.html?id=GTM-WSCLTJS"
										height="0"
										width="0"
										style={{ display: 'none', visibility: 'hidden' }}></iframe>
								</noscript>
							</>
						)}
						{router.pathname !== '/play' && brandConfig.liveChatScript ? (
							<Script id="live-chat">{brandConfig.liveChatScript}</Script>
						) : null}
						{dangerLog && (
							<div className="w-full h-44 bg-red-500 fixed top-0 left-0 z-[5000]">
								<input id="copy" value={fingerprint} readOnly />
								<button
									className="bg-black text-white px-4 py-2"
									onClick={() => {
										document.getElementById('copy').select();
										document.getElementById('copy').setSelectionRange(0, 99999);
										document.execCommand('copy');
									}}>
									COPY
								</button>
							</div>
						)}
						<Component {...pageProps} key={router} />
					</Layout>
				)}
			</AppContextProvider>
		</>
	);
};

export default MyApp;
