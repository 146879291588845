import Image from 'next/image';
import { Fragment } from 'react';
import customLoader from '../../../helpers/custom-image-loader';
function Setting({ Icon, title }) {
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}

	return (
		<Fragment>
			<div
				className={classNames('mr-3 relative flex-shrink-0 h-6 w-6')}
				aria-hidden="true"
			>
				<Image
					src={Icon}
					layout="fill"
					objectFit="fill"
					loader={customLoader}
				/>
			</div>
			<span className="font-quicksandRegular font-medium text-xl side-menu text-[#e7e9ea]">
				{title}
			</span>
		</Fragment>
	);
}
export default Setting;
