const LanguageData = require('./lang/en-US.json');
import localStorage from 'localStorage';

const LanguageHelper = (key, lang = 'en-US') => {
	try {
		if (!lang) return;
		const LanguageData = require(`./lang/${lang}.json`);
		if (!LanguageData[key]) {
			console.error(key, ' is not present!');
			return '';
		}
		return LanguageData[key];
	} catch (error) {
		console.error('language helper error', error);
		localStorage.setItem('lang', 'en-US');
		if (!LanguageData[key]) return '';
		return LanguageData[key];
	}
};

export default LanguageHelper;
