import { ApolloClient, InMemoryCache } from '@apollo/client';

const client = new ApolloClient({
	ssrMode: typeof window === 'undefined',
	uri: process.env.NEXT_PUBLIC_AWS_APPSYNC_GRAPHQLENDPOINT,
	credentials: 'same-origin',
	fetchOptions: {
		credentials: 'include'
	},
	headers: {
		'x-api-key': process.env.NEXT_PUBLIC_AWS_APPSYNC_APIKEY,
	},
	cache: new InMemoryCache(),
	connectToDevTools: true,
});

export default client;
