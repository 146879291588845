import { createWithEqualityFn } from 'zustand/traditional';

const ModalStore = createWithEqualityFn((set) => ({
	mode: '',
	type: '',
	setModal: ({ mode, type }) => set((state) => ({ ...state, mode: mode, type: type })),
	closeModal: () => set((state) => ({ ...state, type: '', mode: '' }))
}));

export default ModalStore;
