import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../context/app.context/app.context';
import LanguageDropdown from '../../molecules/Dropdown/language';
import BrandConfigStore from '../../../context/store/brand-config';

const AffiliateHeader = (p) => {
	const [isLoading, setIsLoading] = useState(true);
	const [profile, setProfile] = useState(false);
	const appContext = useContext(AppContext);
	const { navbar, container } = BrandConfigStore(state => state.config.themeConfig);

	const handleLogout = () => {
		appContext.affiliateLogout();
	};

	useEffect(() => {
		setIsLoading(false);
		setProfile(false);
		if (!appContext.isAffiliateLoading && appContext.affiliateToken) setProfile(true);
	}, [appContext.affiliateToken]);

	return (
		<>
			<header
				className="top-0 w-full font-robotoBold z-50 sticky"
				style={{
					background: navbar.backgroundColor.primary,
					color: navbar.colors.primary
				}}>
				<div className="flex justify-between w-full mx-auto items-center py-2 container">
					<div className="flex justify-start items-center">
						<div className="h-full flex justify-between items-center">
							<div className="flex items-center">
								<a href="/affiliate/dashboard">
									{p.brandConfig.brandLogo ? (
										<img src={p.brandConfig.brandLogo} className="h-10 mr-4" />
									) : (
										<h1 className="font-bold text-xl mr-4">{p.brandConfig.brandName}</h1>
									)}
								</a>
							</div>
						</div>
					</div>

					<div className="ml-auto mr-3">
						<LanguageDropdown {...p} items={p.languages} value={p.selectedLanguage} />
					</div>

					<div className={'flex items-center space-x-5'}>
						{isLoading ? (
							<div className="animate-pulse mobile-s:w-14 mobile-s:h-8 laptop:w-20 laptop:h-10 bg-slate-700 rounded-lg cursor-pointer"></div>
						) : (
							<>
								{profile && (
									<div className="flex items-center">
										<div className="flex items-center cursor-pointer">
											<div className="dropdown dropdown-bottom dropdown-end">
												<label tabIndex={0} className="btn btn-sm btn-ghost w-full rounded-sm">
													{appContext?.affiliateUserInfo?.username}
												</label>
												<ul
													style={{
														background: container.backgroundColor.tertiary,
														color: navbar.colors.primary
													}}
													tabIndex={0}
													className="dropdown-content menu menu-compact p-2 shadow-xl rounded-box w-52">
													<li>
														<a className="active:bg-white/30" href="/affiliate/profile">Profile</a>
													</li>
													<li>
														<a className="active:bg-white/30" href="/affiliate/player-list">Player List</a>
													</li>
													<li>
														<a className="active:bg-white/30" href="/affiliate/sub-affiliate-list">Sub Affiliate List</a>
													</li>
													<li>
														<a className="active:bg-white/30" href="/affiliate/commission-report">Commission Report</a>
													</li>
													<li>
														<a className="btn btn-sm w-full btn-error" onClick={handleLogout}>Logout</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
								)}
							</>
						)}
					</div>
				</div>
			</header>
		</>
	);
};

export default AffiliateHeader;
