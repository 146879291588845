import BrandConfigStore from '../../../context/store/brand-config';

const ModalFooter = (p) => {
	const { children, className } = p;
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);

	return (
		<div className={`p-2 flex gap-3 items-center ${className}`} style={{ color: colors.primary }}>{children}</div>
	);
};

export { ModalFooter };
