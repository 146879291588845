import { useEffect, useRef } from 'react';

const UseOutsideAlerter = (ref, state) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				state(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
};

const OutsideAlerter = (props) => {
	const wrapperRef = useRef(null);
	UseOutsideAlerter(wrapperRef, props.state);

	return <div ref={wrapperRef} className='col-span-1 mobile-xl:col-span-2 mobile-xl:col-end-5 lg:col-span-2 lg:col-end-7 xl:col-span-2 xl:col-end-9'>{props.children}</div>;
};

export default OutsideAlerter;
