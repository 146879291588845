import React, { useContext, useEffect, useMemo, useState } from 'react';
import AppContext from '../../../context/app.context/app.context';
import AuthServices from '../../../services/api/auth/auth.services';
import { ShowPasswordButton } from '../../../components2/molecules/buttons';
import { Modal, ModalContent, ModalFooter, ModalHeader } from '../../../components2/atom/modals';
import BrandConfigStore from '../../../context/store/brand-config';
import ModalStore from '../../../context/store/modal-pop-up';
import GlobalStateStore from '../../../context/store/global-state';
import ConstantDataStore from '../../../context/store/constant-data';
import { shallow } from 'zustand/shallow';
import { InputTextForm } from '../../../components2';
import { Controller, useForm } from 'react-hook-form';
import { SubmitButton } from '../../../components2/atom/buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { regisSchema } from '../../../schema/register-schema';
import usePersistentState from '../../../hooks/usePersistentState';

const RegisterCard = () => {
	const {
		control,
		register,
		handleSubmit,
		reset,
		watch,
		getValues,
		setValue,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(regisSchema),
		defaultValues: {
			username: '',
			email: '',
			password: '',
			phoneNumber: '',
			countryId: '',
			referralCode: '',
			currency: '',
			paymentAccountName: '',
			paymentAccountNumber: '',
			paymentTypeName: '',
			bank: '',
			terms: false
		}
	});

	const { themeConfig, brandId } = BrandConfigStore((state) => state.config);
	const { persistentState: refCode, setPersistentState: setRefCode } = usePersistentState(
		'',
		'referralCode'
	);
	const { backgroundColor, colors } = themeConfig.container;
	const {
		toast,
		toastStatus,
		token,
		setToken,
		referralCode,
		setReferralCode
		// isRendered
	} = GlobalStateStore((state) => state, shallow);
	const { mode, type, closeModal, setModal } = ModalStore((state) => state);
	const isRegister = mode === 'register' && type === 'register';
	const { countriesData, currencies, paymentChannel } = ConstantDataStore(
		(state) => ({
			countriesData: state.countries,
			currencies: state.currencies,
			paymentChannel: state.paymentChannel
		}),
		shallow
	);

	const appContext = useContext(AppContext);
	const [isClicked, setIsClicked] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const countries = useMemo(() => {
		if (countriesData) {
			const refinedData = countriesData
				.map((c) => ({
					id: c.countryId,
					label: `${c.country3Code} ${c.countryCode}`,
					sortParam: c.country3Code
				}))
				.sort((a, b) => (a.sortParam > b.sortParam ? 1 : -1));
			const usa = refinedData?.find((c) => c.sortParam === 'IDN');
			setValue('countryId', usa?.id);
			return refinedData;
		}
	}, [countriesData]);

	const closeModalHandler = () => {
		closeModal();
		reset();
		setShowPassword(false);
	};

	const currencyList = useMemo(() => {
		const data = currencies.filter((c) => c.currencytype === 'FIAT').map((c) => c.currencyname);

		return data;
	}, [currencies]);

	const registerHandler = async (data) => {
		setIsClicked(true);
		const {
			// eslint-disable-next-line no-unused-vars
			terms,
			currency,
			paymentAccountName,
			paymentAccountNumber,
			bank,
			referralCode,
			...restData
		} = data;

		const parsedBank = bank && JSON.parse(bank);

		const bankPayload = {
			currency,
			paymentAccountName,
			paymentAccountNumber,
			paymentTypeName: parsedBank.name,
			paymentId: parsedBank.id
		};

		const payload = {
			brandId: brandId,
			referralCode: referralCode ?? '',
			...restData
		};

		if (currency && paymentAccountName && paymentAccountNumber) payload.bank = bankPayload;

		try {
			const response = await AuthServices.Register3(payload, token);

			const data = response.data;

			appContext.setToken(data.playerToken);
			setToken(data.playerToken);
			setReferralCode('');
			setRefCode('');
			closeModalHandler();
			reset();
			// google tag manager
			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				brandId: payload.brandId,
				loginSuccess: true
			});
		} catch (error) {
			toast(toastStatus.ERROR, error.message);
		} finally {
			setIsClicked(false);
		}
	};

	const paymentChannelList = useMemo(() => {
		const filteredPaymentChannel = paymentChannel
			.filter((pc) => pc.currency === getValues('currency') && pc.paymentType === 'BANK')
			.map((pc) => ({
				value: JSON.stringify({ id: pc.paymentId, name: pc.paymentName }),
				paymentId: pc.paymentId,
				paymentName: pc.paymentName
			}));

		return filteredPaymentChannel;
	}, [paymentChannel, watch('currency')]);

	useEffect(() => {
		setValue('referralCode', refCode);
		setReferralCode(refCode);
	}, [refCode, mode]);

	const elms = useMemo(() => {
		return [
			{
				label: 'label.formUserName',
				placeholder: 'input.userNamePlaceholder',
				name: 'username'
			},
			{
				label: 'label.formEmail',
				placeholder: 'input.emailPlaceholder',
				name: 'email'
			},
			{
				label: 'label.formPassword',
				placeholder: 'input.passwordPlaceholder',
				type: showPassword ? 'text' : 'password',
				name: 'password',
				endAdornment: (
					<ShowPasswordButton showPassword={showPassword} setShowPassword={setShowPassword} />
				)
			},
			{
				label: 'label.formPhoneNumber',
				placeholder: 'input.phoneNumberPlaceholder',
				name: 'phoneNumber',
				// startAdornment: <Select className="select-sm w-fit" options={countries} value={countryId} onChange={(e) => setCountryId(e.target.value)} />
				startAdornment: (
					<Controller
						name="countryId"
						control={control}
						render={() => (
							<select
								className="text-sm leading-none text-white border-0 ring-0 outline-none select select-sm w-fit bg-inherit disabled:bg-slate-200 disabled:text-gray-400 focus:outline-none active:outline-none focus:ring-0 active:ring-0 focus:border-0 active:border-0"
								{...register('countryId')}
								style={{ backgroundColor: 'inherit', color: colors.secondary }}>
								<option
									value=""
									disabled
									style={{ backgroundColor: backgroundColor.primary, color: colors.secondary }}>
									{appContext.t('label.country')}
								</option>
								{countries.map((c) => (
									<option
										value={c.id}
										key={c.id}
										disabled={!!c.disabled}
										style={{ backgroundColor: backgroundColor.primary, color: colors.secondary }}>
										{c.label}
									</option>
								))}
							</select>
						)}
					/>
				)
			}
		];
	}, [countries, showPassword]);

	const elmsBottom = useMemo(() => {
		return [
			// {
			// 	label: 'label.formBankName',
			// 	placeholder: 'input.bankNamePlaceholder',
			// 	name: 'paymentTypeName'
			// },
			{
				label: 'label.formBankOwnerName',
				placeholder: 'input.bankOwnerPlaceholder',
				name: 'paymentAccountName'
			},
			{
				label: 'label.formBankAccountNumber',
				placeholder: 'input.bankAccountPlaceholder',
				name: 'paymentAccountNumber'
			},
			{
				label: 'label.formReferralCode',
				placeholder: 'input.referralCodePlaceholder',
				name: 'referralCode'
			}
		];
	}, []);

	const a = {
		openModal: isRegister,
		setOpenModal: closeModalHandler
	};

	return (
		<>
			<Modal {...a}>
				<form onSubmit={handleSubmit(registerHandler)} className="space-y-3">
					<ModalHeader setOpenModal={closeModalHandler} closeButton />

					<ModalContent>
						{elms.map((elm) => (
							<React.Fragment key={elm.name}>
								<InputForm
									register={register}
									errors={errors}
									label={elm.label}
									placeholder={elm.placeholder}
									name={elm.name}
									{...elm}
								/>
								{elm.name === 'phoneNumber' && errors.countryId?.message ? (
									<p className="text-red-500">{errors.countryId?.message}</p>
								) : null}
							</React.Fragment>
						))}

						<div>
							<h1 className="pt-5 text-2xl underline underline-offset-4">
								{appContext.t('title.bankDetails')}
							</h1>

							<div className="mb-4">
								<Controller
									name="currency"
									control={control}
									render={() => (
										<div
											className="pb-2 form-control"
											style={{ borderBottom: '1px solid', borderColor: backgroundColor.secondary }}>
											<label className="label">
												<span className="text-xl label-text" style={{ color: colors.primary }}>
													{appContext.t('label.bankCurrency')}
												</span>
											</label>
											<select
												className="w-full text-sm leading-none text-white border-0 ring-0 outline-none select select-sm bg-inherit disabled:bg-slate-200 disabled:text-gray-400 focus:outline-none active:outline-none focus:ring-0 active:ring-0 focus:border-0 active:border-0"
												{...register('currency')}
												style={{ backgroundColor: 'inherit', color: colors.secondary }}>
												<option
													value=""
													style={{
														backgroundColor: backgroundColor.primary,
														color: colors.secondary
													}}>
													{appContext.t('select.currency')}
												</option>
												{currencyList.map((c) => (
													<option
														value={c}
														key={c}
														style={{
															backgroundColor: backgroundColor.primary,
															color: colors.secondary
														}}>
														{c}
													</option>
												))}
											</select>
										</div>
									)}
								/>
								{errors.currency ? <p className="text-red-500">{errors.currency.message}</p> : null}
							</div>

							<div>
								<Controller
									name="bank"
									control={control}
									render={() => (
										<div
											className="pb-2 form-control"
											style={{ borderBottom: '1px solid', borderColor: backgroundColor.secondary }}>
											<label className="label">
												<span className="text-xl label-text" style={{ color: colors.primary }}>
													{appContext.t('label.formBankName')}
												</span>
											</label>
											<select
												className="w-full text-sm leading-none text-white border-0 ring-0 outline-none select select-sm bg-inherit disabled:bg-slate-200 disabled:text-gray-400 focus:outline-none active:outline-none focus:ring-0 active:ring-0 focus:border-0 active:border-0"
												{...register('bank')}
												disabled={getValues('currency') === ''}
												style={{ backgroundColor: 'inherit', color: colors.secondary }}>
												<option
													value=""
													style={{
														backgroundColor: backgroundColor.primary,
														color: colors.secondary
													}}>
													{appContext.t('select.paymentChannel')}
												</option>

												{paymentChannelList.map((pc) => (
													<option
														value={pc.value}
														key={pc.paymentId}
														style={{
															backgroundColor: backgroundColor.primary,
															color: colors.secondary
														}}>
														{pc.paymentName}
													</option>
												))}
											</select>
										</div>
									)}
								/>

								{errors.bank ? <p className="text-red-500">{errors.bank.message}</p> : null}
							</div>
						</div>

						{elmsBottom.map((elm) =>
							elm.name === 'referralCode' ? (
								!referralCode ? (
									<InputForm
										register={register}
										errors={errors}
										key={elm.name}
										label={elm.label}
										placeholder={elm.placeholder}
										name={elm.name}
										{...elm}
									/>
								) : null
							) : (
								<React.Fragment key={elm.name}>
									<InputForm
										register={register}
										errors={errors}
										label={elm.label}
										placeholder={elm.placeholder}
										name={elm.name}
										{...elm}
									/>
									{elm.name === 'phoneNumber' && errors.countryId?.message ? (
										<p className="text-red-500">{errors.countryId?.message}</p>
									) : null}
								</React.Fragment>
							)
						)}

						<div>
							<Controller
								name="terms"
								control={control}
								render={() => (
									<div className="form-control">
										<label className="flex items-center space-x-5 cursor-pointer label">
											<input
												name="terms"
												type="checkbox"
												className="bg-white rounded checkbox checkbox-xs"
												{...register('terms')}
											/>
											<span className="text-sm label-text" style={{ color: colors.secondary }}>
												{appContext.t('checkbox.formAgeConsent')}
											</span>
										</label>
									</div>
								)}
							/>
							{errors.terms ? <p className="text-red-500">{errors.terms.message}</p> : null}
						</div>
					</ModalContent>

					<ModalFooter>
						<div className="space-y-2 w-full">
							<SubmitButton
								className="py-2.5 rounded-full w-full"
								disabled={isClicked || !token}
								isClicked={isClicked}
								style={{
									backgroundColor: backgroundColor.accent2,
									filter: !token && 'brightness(50%)',
									color: colors.accent
								}}>
								{token ? (
									appContext.t('btn.register')
								) : (
									<span
										className="loading loading-sm loading-dots"
										style={{ color: colors.primary }}
									/>
								)}
							</SubmitButton>

							{!referralCode ? (
								<p className="text-sm text-center" style={{ color: colors.primary }}>
									{appContext.t('text.alreadyHaveAccount')}
									<button
										className="underline"
										onClick={(e) => {
											e.preventDefault();
											closeModalHandler();
											setModal({ mode: 'login', type: 'login' });
										}}>
										{appContext.t('link.login')}
									</button>
								</p>
							) : null}
						</div>
					</ModalFooter>
				</form>
			</Modal>
		</>
	);
};

const InputForm = ({ register, errors, label, placeholder, name, ...rest }) => {
	const appContext = useContext(AppContext);
	const { backgroundColor, colors } = BrandConfigStore(
		(state) => state.config.themeConfig.container
	);

	return (
		<div className="space-y-2">
			<InputTextForm
				label={appContext.t(label)}
				labelTop
				labelClassName="text-xl"
				inputClassName="rounded-none"
				placeholder={appContext.t(placeholder)}
				register={register}
				name={name}
				inputStyle={{
					borderBottom: '1px solid',
					borderColor: errors[name] ? 'red' : backgroundColor.secondary,
					color: colors.primary
				}}
				{...rest}
			/>

			{errors[name] ? <p className="text-red-500">{errors[name]?.message}</p> : null}
		</div>
	);
};

export default RegisterCard;
