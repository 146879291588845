import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import AppContext from '../../../context/app.context/app.context';
import Navbar from '../Navbar/Navbar';
import Image from 'next/image';
import Balance from '../../molecules/Balance/Balance';
import customLoader from '../../../helpers/custom-image-loader';
import LanguageDropdown from '../../molecules/Dropdown/language';
import BrandConfigStore from '../../../context/store/brand-config';
import ModalStore from '../../../context/store/modal-pop-up';
import GlobalStateStore from '../../../context/store/global-state';

const Header = (p) => {
	const {
		config: { themeConfig, brandLogo, brandName, navigation },
		languages
	} = BrandConfigStore((state) => ({ config: state.config, languages: state.languages }));
	const setModal = ModalStore((state) => state.setModal);
	const { token, setHeaderHeight } = GlobalStateStore((state) => ({
		token: state.token,
		setHeaderHeight: state.setHeaderHeight
	}));
	const { backgroundColor, colors, walletColor } = themeConfig.navbar;
	const appContext = useContext(AppContext);

	const [isLoading, setIsLoading] = useState(true);
	const [profile, setProfile] = useState(false);

	const router = useRouter();
	const headerRef = useRef(null);

	useEffect(() => {
		setHeaderHeight(headerRef.current.getBoundingClientRect().height);
	}, []);

	useEffect(() => {
		setIsLoading(false);
		setProfile(false);
		if (token && token.split(':')[0].toLowerCase() !== 'sessiontoken') setProfile(true);
	}, [token]);

	return (
		<>
			<header
				className={`top-0 w-full font-robotoBold shadow-lg z-[100] ${
					router.pathname == '/play' ? 'relative' : 'sticky py-2'
				}`}
				ref={headerRef}
				style={{
					background: backgroundColor.primary,
					// background: 'transparent',
					color: colors.primary
				}}>
				<div className="flex justify-between w-full mx-auto items-center myContainer px-4">
					<div className="flex items-center gap-3">
						{/* <div onClick={`${router.pathname == '/play' ? '#' : '/'}`}> */}
						<div
							className="cursor-pointer"
							onClick={() => {
								if (router.pathname !== '/play') window.location = '/';
							}}>
							{brandLogo ? (
								// <img src={brandLogo} className="logoIcon w-full max-h-10 object-contain" /> :
								<img src={brandLogo} className="logoIcon h-full min-w-[8rem] object-contain" />
							) : (
								<h1 className="font-bold text-xl mr-4">{brandName}</h1>
							)}
						</div>
						{router.pathname == '/play' || (
							<div className="hidden md:block">
								<Navbar options={navigation.sideBar} />
							</div>
						)}
					</div>

					<div className="ml-auto hidden lg:block mr-2">
						<LanguageDropdown items={languages} value={p.selectedLanguage} />
					</div>

					<div className={'flex items-center '}>
						{isLoading ? (
							<div className="animate-pulse mobile-s:w-14 mobile-s:h-8 laptop:w-20 laptop:h-10 bg-slate-700 rounded-lg cursor-pointer"></div>
						) : (
							<>
								{profile ? (
									<>
										<div className={'flex items-center'}>
											{router.pathname == '/play' ? null : (
												<div
													className={'hidden mobile-xl:flex items-center mr-4 cursor-pointer'}
													onClick={() => router.push('/account/reward/bonus/')}>
													<Image
														src={'/icons/Header/gift.png'}
														loader={customLoader}
														width={22}
														height={22}
													/>
												</div>
											)}
											<div
												className={'hidden mobile-xl:flex items-center cursor-pointer'}
												onClick={
													router.pathname == '/play'
														? () => {}
														: () => router.push('/account/profile/information/')
												}>
												<div className={'font-quicksandSemi'}>{appContext?.userInfo?.username}</div>
												{router.pathname == '/play' ? null : (
													<Image
														src={'/icons/Header/Balance/expand-white.png'}
														className={'-rotate-90'}
														loader={customLoader}
														width={22}
														height={22}
													/>
												)}
											</div>
											<div className="ml-1">
												<Balance {...p} />
											</div>
										</div>
									</>
								) : (
									<>
										{router.pathname !== '/login' && router.pathname !== '/register' ? (
											<>
												<button
													className=" font-quicksandSemi tracking-wider text-base mobile-xl:text-lg xl:text-base hover:shadow-sm mr-4 whitespace-nowrap"
													style={{
														color: colors.primary,
														borderColor: colors.primary
													}}
													onClick={(e) => {
														e.preventDefault();
														setModal({ mode: 'login', type: 'login' });
													}}>
													{appContext.t('btn.login')}
												</button>

												<button
													className=" font-quicksandSemi tracking-wider text-base mobile-xl:text-lg xl:text-base hover:shadow-sm logoIcon px-4 w-full h-full rounded-lg"
													style={{
														background: walletColor,
														color: colors.secondary
													}}
													onClick={(e) => {
														e.preventDefault();
														setModal({ mode: 'register', type: 'register' });
													}}>
													{appContext.t('btn.register')}
												</button>
											</>
										) : null}
									</>
								)}
							</>
						)}
					</div>
				</div>
			</header>
		</>
	);
};

export default Header;
