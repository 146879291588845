import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { IconButton } from '../../atom/buttons';
import BrandConfigStore from '../../../context/store/brand-config';

const ShowPasswordButton = (p) => {
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const changeHandler = (e) => {
		e.preventDefault();
		p.setShowPassword(!p.showPassword);
	};
	
	return (
		<IconButton
			tabIndex={-1}
			onClick={changeHandler}
			type='button'
			icon={
				p.showPassword ? (
					<AiOutlineEyeInvisible
						className="text-2xl"
						style={{ color: colors.primary }}
					/>
				) : (
					<AiOutlineEye
						className="text-2xl"
						style={{ color: colors.primary }}
					/>
				)}
		/>
	);
};

export { ShowPasswordButton };
