import BrandConfigStore from '../../../context/store/brand-config';

const Checkbox = (p) => {
	const { className, labelClassName, containerClassName, name, label, ...rest } = p;
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);
	return (
		<label htmlFor={name} className={`label cursor-pointer space-x-2 flex items-center ${containerClassName ?? ''}`}>
			<input id={name} type="checkbox" className={`checkbox ${className ?? ''}`} {...rest} />
			<span className={`label-text ${labelClassName ?? ''}`} style={{ color: colors.secondary }}>{label}</span>
		</label>
	);
};

export { Checkbox };
