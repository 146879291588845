import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../../../services/ApolloClient';
import Chat from '../Chat';
import BrandConfigStore from '../../../context/store/brand-config';

const Container = (p) => {
	const { children } = p;
	const router = useRouter();
	const [userAgent, setUserAgent] = useState({
		isMobile: null,
		name: '',
	});

	const { showBrandChat } = BrandConfigStore(state => state.config);

	useEffect(() => {
		if (window) {
			setUserAgent({...userAgent, name: window.navigator.vendor, isMobile: 'ontouchstart' in document.documentElement});
		}
	}, []);

	return (
		<ApolloProvider client={client}>
			<div className={`text-white ${(router.pathname == '/play' && userAgent.isMobile) ? '' : router.pathname == '/play' ? '' : 'myContainer mx-auto z-0 min-h-screen'}`}>
				<main>{children}</main>
				{showBrandChat && router.pathname !== '/play' && <Chat {...p} />}
			</div>
		</ApolloProvider>
	);
};

export default Container;