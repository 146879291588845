import { createWithEqualityFn } from 'zustand/traditional';
import { GetBrandSetting } from '../../../utils/helper';
import BrandSetting from '../../../public/brandSettings.json';
import BrandServices from '../../services/api/brand';

const BrandConfigStore = createWithEqualityFn((set) => ({
	config: BrandSetting,
	languages: [{ langCode: 'id-ID', lang: 'Bahasa Indonesia' }],
	isLoadingConfig: true,
	setBrandSetting: (value) => set((state) => (state.config = value)),
	getBrandSetting: GetBrandSetting().then((response) => {
		const languageOptions = response.langOption.filter((l) => l.isDisplay);
		set((state) => ({
			...state,
			config: { ...state.config, ...response },
			languages: languageOptions,
			isLoadingConfig: false
		}));
	}),
	getBrandSetting2: () =>
		BrandServices.GetBrandSetting({ brandId: BrandSetting.brandId, type: 'dynamic' }).then(
			(response) => {
				set((state) => ({ ...state, config: { ...state.config, ...response } }));
			}
		)
}));

export default BrandConfigStore;
