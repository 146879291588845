import { useContext } from 'react';
import AppContext from '../../../context/app.context/app.context';
import Sidebar from '../../molecules/Sidebar/Sidebar';
import ComingSoon from '../../molecules/Popup/ComingSoon';
import BrandConfigStore from '../../../context/store/brand-config';

export default function ModalLayout(p) {
	const { children } = p;
	const appContext = useContext(AppContext);
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);

	return (
		<div className="relative" style={{
			backgroundColor: backgroundColor.primary,
			color: colors.primary,
		}}>
			<div
				className={`${
					appContext.openSidebar ? 'translate-x-0' : '-translate-x-[100%]'
				} fixed top-0 left-0 bottom-0 right-0 z-[151] transition-all duration-500 inline-block`}
			>
				<Sidebar
					{...p}
					setIsClicked={appContext.setOpenSidebar}
					isClicked={appContext.openSidebar}
				/>
			</div>

			<div className="relative">
				{appContext.openSidebar ? (
					<div
						className={`fixed inset-0 bg-black/40 backdrop-blur-sm z-[150] transition-all duration-500 ${
							appContext.openSidebar
								? 'translate-y-0 '
								: 'invisible opacity-0 translate-y-0'
						}
            }`}
					></div>
				) : null}

				<div
					className={`${
						appContext.comingSoon ? '' : ' invisible'
					} fixed inset-0 z-[100] overflow-hidden transition-all duration-300`}
				>
					<div className="absolute top-0 left-0 right-0 bottom-0 backdrop-blur-sm bg-black/20"></div>

					<ComingSoon {...p} />
				</div>
				<>{children}</>
			</div>
		</div>
	);
}
