/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import localStorage from 'localStorage';
import UserServices from '../../services/api/user/user.services';
import AppContext from './app.context';
import userServices from '../../services/api/affiliate/user.services';
import LanguageHelper from '../../helpers/l10n.helper';
import GlobalStateStore from '../store/global-state';
import AuthServices from '../../services/api/auth/auth.services';

import BrandSetting from '../../../public/brandSettings.json';

const AppContextProvider = (props) => {
	const selectedLanguage = localStorage.getItem('lang');
	const [userInfo, setUserInfo] = useState({});
	const [token, setToken] = useState('');

	const [isLoading, setIsLoading] = useState(true); // set is loading true
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isAffiliateLoading, setIsAffiliateLoading] = useState(false); // set is loading true

	const [popLogin, setPopLogin] = useState(false);
	const [popRegister, setPopRegister] = useState(false);
	const [openSidebar, setOpenSidebar] = useState(false);
	const [comingSoon, setComingSoon] = useState(false);

	const [affiliateUserInfo, setAffiliateUserInfo] = useState({});
	const [affiliateToken, setAffiliateToken] = useState('');

	const {
		token: zToken,
		fingerprint,
		setIsTokenValid
	} = GlobalStateStore((state) => ({
		token: state.token,
		fingerprint: state.fingerprint,
		setIsTokenValid: state.setIsTokenValid
	}));

	useEffect(() => {
		setIsLoading(true);

		if (localStorage.getItem('token')) {
			VerifyToken(localStorage.getItem('token'));
		}

		if (localStorage.getItem('affiliateToken')) {
			VerifyAffiliateToken(localStorage.getItem('affiliateToken'));
		}

		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (!isLoggedIn && !isLoading && zToken && zToken !== '') {
			const token = zToken.split(':')[0].toLowerCase();
			if (['sessiontoken'].includes(token) && fingerprint && fingerprint !== '') {
				VerifySessionToken(zToken);
			}
		}
	}, [zToken, isLoggedIn, isLoading, fingerprint]);

	const VerifySessionToken = (token) => {
		AuthServices.TsSessionTokenAuthorizer({
			brandId: BrandSetting.brandId,
			fingerprint: fingerprint,
			sessionToken: token
		})
			.then((res) => {
				setIsTokenValid(res?.data?.isTokenValid ?? false);
			})
			.catch(() => {
				setIsTokenValid(false);
			});
	};

	const VerifyToken = (token) => {
		setIsLoading(true);
		UserServices.GetUserInformation()
			.then((response) => {
				setUserInfo(response);
				setToken(token);
				setIsLoggedIn(true);
				setIsLoading(false);
			})
			.catch(() => {
				setIsLoggedIn(false);
				setIsLoading(false);
				// logout();
			})
			.finally(() => {
				setIsLoading(false);
			});

		setIsLoading(false);
	};

	const VerifyAffiliateToken = (token) => {
		setIsAffiliateLoading(true);
		userServices
			.GetProfile()
			.then((response) => {
				setAffiliateUserInfo(response.data.data);
				setAffiliateToken(token);
			})
			.catch(() => {
				console.warn('Detected another active session, logging out.');
				affiliateLogout();
			});
		setIsAffiliateLoading(false);
	};

	const setNewToken = (token) => {
		if (token) {
			localStorage.setItem('token', token);
			VerifyToken(token);
			sessionStorage.setItem('authorized', 'true');
		}
	};

	const setNewAffiliateToken = (token) => {
		if (token) {
			localStorage.setItem('affiliateToken', token);
			VerifyAffiliateToken(token);
		}
	};

	// Balance
	const affiliateLogout = async () => {
		setIsAffiliateLoading(true);
		setAffiliateToken('');
		setAffiliateUserInfo([]);
		localStorage.removeItem('affiliateToken');
		setIsAffiliateLoading(false);
	};

	const logout = async () => {
		if (localStorage.getItem('token')) {
			setUserInfo([]);
			localStorage.removeItem('token');
			localStorage.removeItem('balance');
		}

		setIsLoggedIn(false);
		setIsLoading(false);
		setIsTokenValid(false);
	};

	const t = (key) => LanguageHelper(key, selectedLanguage);

	const contextObject = {
		userInfo: userInfo,
		setUserInfo: setUserInfo,
		token: token,
		setToken: setNewToken,
		logout: logout,
		affiliateLogout,
		isLoading: isLoading,
		isLoggedIn: isLoggedIn,
		popLogin: popLogin,
		setPopLogin: setPopLogin,
		popRegister: popRegister,
		setPopRegister: setPopRegister,
		openSidebar: openSidebar,
		setOpenSidebar: setOpenSidebar,
		affiliateToken,
		setAffiliateToken: setNewAffiliateToken,
		affiliateUserInfo,
		setAffiliateUserInfo,
		isAffiliateLoading,
		setIsAffiliateLoading,
		comingSoon,
		setComingSoon,
		t
	};

	return <AppContext.Provider value={contextObject}>{props.children}</AppContext.Provider>;
};

export default AppContextProvider;
