const GenerateFingerprint = async (checks) => {
	if (!checks) return console.error('At least one check is required!');

	const output = await Promise.all(checks.map((file) => require(`./fingerprints/${file}`)()));

	// const output = await Promise.all(
	// 	checks.map(async (file) => {
	// 		const value = await require(`./fingerprints/${file}`)();
	// 		return {
	// 			[file]: value
	// 		};
	// 	})
	// );

	// const platform = navigator.platform;

	// console.log('fingerprint : ', output);
	// console.log('platform : ', platform);
	// console.log('new Date().getTimezoneOffset(); : ', new Date().getTimezoneOffset());

	return output.join('|').toString();
};

const FingerprintHelper = {
	GenerateFingerprint
};

export default FingerprintHelper;
