import { useContext } from 'react';
import AppContext from '../../../context/app.context/app.context';
import Link from 'next/link';
import BrandConfigStore from '../../../context/store/brand-config';

const ModalLayoutAffiliate = (p) => {
	const { children, menus } = p;
	const { backgroundColor, colors } = BrandConfigStore(state => state.config.themeConfig.container);
	const appContext = useContext(AppContext);

	const handleLogout = (e) => {
		e.preventDefault();
		appContext.affiliateLogout();
	};

	return (
		<div
			className="drawer"
			style={{
				backgroundColor: backgroundColor.primary,
				color: colors.primary,
			}}
		>
			<input id="my-drawer" type="checkbox" className="drawer-toggle" />
			<div className="drawer-content">
				{children}
			</div> 
			<div className="drawer-side">
				<label htmlFor="my-drawer" className="drawer-overlay"></label>
				<div
					className="menu p-4 w-80 h-full space-y-4"
					style={{
						backgroundColor: backgroundColor.primary,
						color: colors.primary,
					}}
				>
					{/* Sidebar content here */}
					{menus.map(menu => (
						<Link href={menu.link} key={menu.label}>
							<a>{menu.label}</a>
						</Link>
					))}
					<button className="btn-sm rounded-md btn-square w-full btn-error" onClick={handleLogout}>Logout</button>
				</div>
			</div>
		</div>
	);
};

export default ModalLayoutAffiliate;
