import { useContext } from 'react';
import AppContext from '../../../context/app.context/app.context';

const ComingSoon = () => {
	const appContext = useContext(AppContext);

	return (
		<div
			className={`${
				appContext.comingSoon ? '' : 'scale-0'
			} relative top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] transition-all duration-300`}>
			<div className="relative mx-auto text-white max-w-[21rem] mobile-xl:max-w-[30rem] tablet:min-w-[30rem] rounded-[0.625rem] px-7 py-24 bg-[#00111D] leading-none shadow-[0_0_9px_3px_rgba(231,233,234,.09)]">
				<div
					className="absolute top-7 right-5 cursor-pointer"
					onClick={() => {
						appContext.setComingSoon(false);
					}}>
					<div className="w-[1.25rem] h-[2px] bg-white rotate-45 "></div>
					<div className="w-[1.25rem] h-[2px] bg-white -rotate-45 -translate-y-[2px]"></div>
				</div>
				<h1 className="text-xl font-bold">{appContext.t('text.comingsoon1')}</h1>
				<p className="font-lighter">{appContext.t('text.comingsoon2')}</p>
			</div>
		</div>
	);
};

export default ComingSoon;
