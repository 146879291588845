import { toast } from 'react-toastify';
import { createWithEqualityFn } from 'zustand/traditional';
import getToken from '../../services/getToken';
import UserServices from '../../services/api/user/user.services';
import CreateObj from '../../helpers/create-map-object.helper';

const GlobalStateStore = createWithEqualityFn((set) => ({
	toastStatus: { SUCCESS: 'success', INFO: 'info', ERROR: 'error' },
	referralCode: '',
	loading: true,
	headerHeight: 0,
	url: '',
	setHeaderHeight: (value) => set((state) => ({ ...state, headerHeight: value })),
	isLoggedIn: false,
	isRendered: false,
	token: getToken(),
	toast: (status, message) => toast[status](message),
	setToken: (token) => set((state) => ({ ...state, token })),
	tsToken: null, // turnstile token
	setTsToken: (token) => set((state) => ({ ...state, tsToken: token })), // set turnstile token
	clearTsToken: () => set((state) => ({ ...state, tsToken: null })), // clear turnstile token
	tsTokenValid: false, // turnstile valid or not
	setTsTokenValid: (bool) => set((state) => ({ ...state, tsTokenValid: bool })), // set turnstile valid
	isTokenValid: false, // turnstile valid or not
	setIsTokenValid: (bool) => set((state) => ({ ...state, isTokenValid: bool })), // set turnstile valid
	fingerprint: null,
	setFingerprint: (data) => set((state) => ({ ...state, fingerprint: data })),
	clearFingerprint: () => set((state) => ({ ...state, fingerprint: null })),
	balances: {},
	currencySettings: {},
	setUrl: (value) => set((state) => ({ ...state, url: value })),
	menuClicked: (id) => {
		const elm = document.querySelector(id);
		window.scrollTo(0, elm.getBoundingClientRect().top + window.scrollY);
	},
	setLoading: (bool) => set((state) => ({ ...state, loading: bool })),
	getBalances: async (payload) => {
		if (getToken()?.split(':')[0].toLowerCase() === 'sessiontoken') return;
		const response = await UserServices.GetCurrentBalance2(payload);

		const { walletBalance, walletSetting } = response;
		const mapObj = CreateObj.newMap(walletBalance);
		const refinedData = { ...response, walletBalance: mapObj };
		localStorage.setItem('balances', JSON.stringify(refinedData));
		set((state) => ({
			...state,
			balances: refinedData,
			currencySettings: walletSetting,
			loading: false
		}));

		return refinedData;
	},
	setLocale: (lang) => {
		localStorage.setItem('lang', lang);
	},
	setReferralCode: (code) => set((state) => ({ ...state, referralCode: code })),
	setBalances: (data) => set((state) => ({ ...state, balances: data })),
	setIsRendered: (bool) => set((state) => ({ ...state, isRendered: bool }))
}));

export default GlobalStateStore;
