import { twMerge } from 'tailwind-merge';
import BrandConfigStore from '../../../context/store/brand-config';

export const InputTextForm = ({ label, labelClassName, name, labelStyle, inputClassName, register, labelTop, validate, labelBottom, startAdornment, endAdornment, inputStyle, ...p }) => {
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);

	return (
		<>
			<div className="form-control w-full border-b">
				{label && labelTop ? (
					<label className='label'>
						<span className={twMerge('label-text', labelClassName)} style={labelStyle ?? { color: colors.primary }}>{label}</span>
					</label>
				) : null}

				<div className={twMerge('flex gap-2 items-center')} style={ inputStyle ?? {} }>
					{startAdornment ?? null}
					<input type="text" className={twMerge('input w-full bg-inherit border-none outline-none focus:outline-none focus:ring-0', inputClassName)} onInvalid={(e) => console.log(e)} {...register(name, validate)} {...p} />
					{endAdornment ?? null}
				</div>

				{label && labelBottom ? (
					<label className='label'>
						<span className={twMerge('label-text', labelClassName)} style={labelStyle ?? { color: colors.primary }}>{label}</span>
					</label>
				) : null}
			</div>
		</>
	);
};
