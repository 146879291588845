import Image from 'next/image';
import { useContext, useEffect,  useMemo,  useState } from 'react';
import AppContext from '../../../context/app.context/app.context';
import CurrencyFormatHelper from '../../../helpers/currency-format.helper';
import customLoader from '../../../helpers/custom-image-loader';
import ChooseCurrencyModal from './ChooseCurrencyModal';
import { useRouter } from 'next/router';
import OutsideAlerter from '../OutsideClick/OutsideClick';
// eslint-disable-next-line no-unused-vars
import { Amplify, API, graphqlOperation } from 'aws-amplify';
import BalanceSubscription from '../../../graphql/subscriptions/balance';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
// import ConstantDataStore from '../../../context/store/constant-data';

const Balance = (p) => {
	const { updatingBalance, setUpdatingBalance } = p;
	const [onClickChooseCurrency, setOnClickChooseCurrency] = useState(false);
	const { themeConfig, walletIcon } = BrandConfigStore(state => state.config);
	// const { getCurrencyList } = ConstantDataStore(state => ({ getCurrencyList: state.getCurrencyList }));
	const {
		token,
		balances,
		getBalances,
		loading,
		setBalances,
		toast,
		toastStatus
	} = GlobalStateStore(state => ({
		token: state.token,
		balances: state.balances,
		getBalances:
		state.getBalances,
		loading: state.loading,
		setBalances: state.setBalances,
		toast: state.toast,
		toastStatus: state.toastStatus
	}));
	
	const { walletColor } = themeConfig.navbar;

	const router = useRouter();

	const appContext = useContext(AppContext);

	// might be used when refactoring balance
	const balanceList = useMemo(() => {
		if (token || token?.split(':')[0].toLowerCase() !== 'sessiontoken') {
			if (balances?.walletBalance) return Object.keys(balances?.walletBalance ?? {}).map(c => balances.walletBalance[c]);
			else return [];
		} else {
			return [];
		}
	}, [balances]);

	useEffect(() => {
		const handleStorageChange = () => {
			if (localStorage.getItem('balances')) {
				const newBal = JSON.parse(localStorage.getItem('balances'));
				setBalances(newBal);
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, []);

	useEffect(() => {
		if (token && token?.split(':')[0].toLowerCase() !== 'sessiontoken') {
			getBalances({ type: 'all' });
			// getCurrencyList({ type: 'get', currencyType: 'all' });
		}
	}, [token, loading]);

	useEffect(() => {
		const oldWallet = balances.walletBalance;
		const balanceSub = API
			.graphql(graphqlOperation(BalanceSubscription.SubscribeBalance, { brandUsername: token?.split(':')[0] }, token))
			.subscribe({
				next: ({value}) => {
					const newBalance = value.data.newBalance;
					const walletBalance = JSON.parse(newBalance.walletBalance);
					const mapWallet = new Map(Object.entries(oldWallet));
					for (const x in walletBalance) {
						mapWallet.set(x, { ...mapWallet.get(x), totalBalance: walletBalance[x]});
					}

					const stringBalance = JSON.stringify(Object.fromEntries(mapWallet));
					const parsedBalance = JSON.parse(stringBalance);

					const refined = { ...newBalance, walletBalance: parsedBalance };
					setBalances(refined);
				}
			});

		const notifSub = API
			.graphql(graphqlOperation(BalanceSubscription.SubscribeDepo, { brandUsername: token?.split(':')[0] }, token))
			.subscribe({
				next: ({ value }) => {
					const response = value.data.playerNotifDepoWd;
					toast(toastStatus.INFO, `Your ${response.transactionType.toLowerCase()} has been ${response.transactionStatus.toLowerCase() === 'success' ? 'approved' : 'declined'}`);
				}
			});

		return () => {
			balanceSub.unsubscribe();
			notifSub.unsubscribe();
		};
	}, [token, balances]);

	const onClickBalance = () => {
		setOnClickChooseCurrency(!onClickChooseCurrency);
	};
	
	p = {
		...p,
		balances,
		updatingBalance,
		setUpdatingBalance,
	};


	return (
		<>
			{token ? (
				<div className='flex'>
					{/* <CustomDropdown
						containerClassName="btn-md px-2 h-14"
						dropdownClassName="dropdown-end"
						contentClassName="mt-3 h-[80rem]"
						label={(
							<div>
								<div className="flex items-center gap-1">
									<img src={balances?.walletBalance?.[balances?.activeWallet].currencyLogo} className="h-4 w-fit aspect-square" alt="" />
									<span className="text-base font-medium hidden lg:inline-block">{balances?.activeWallet}</span>
									<FaChevronDown />
								</div>
								<p className="text-start text-sm">{CurrencyFormatHelper.balanceCurrency(balances?.walletBalance?.[balances?.activeWallet].totalBalance, 5)}</p>
							</div>
						)}
					>
						
						{currencyList.map(c => (
							<CustomDropdownChildren className="btn-block min-w-[10rem] justify-between" key={c.currency}>
								<div className="flex justify-between w-full items-center gap-20 text-justify">
									<div className="flex items-center gap-1">
										<img src={c.currencyLogo} className="h-4 w-fit aspect-square" alt="" />
										<span className="text-base font-medium">{c.currency}</span>
									</div>
									<p className="text-end text-sm w-full">{CurrencyFormatHelper.balanceCurrency(c.totalBalance, 5)}</p>
								</div>	
							</CustomDropdownChildren>
						))}
					</CustomDropdown> */}

					<OutsideAlerter state={setOnClickChooseCurrency}>
						<div className="block">
							<div className="flex items-center space-x-3">
								<div className={`flex mobile-s:justify-end laptop:justify-start items-center w-auto rounded-lg cursor-pointer ${router.pathname == '/play' ? '' : 'bg-gray-500/50'}`}>
									{balanceList.length ? (
										router.pathname == '/play' ? (
											<div className="block mobile-s:font-robotoLight laptop:font-quicksandSemi">
												<div className="flex items-center justify-center laptop:justify-start">
													<p className="mobile-s:hidden laptop:flex leading-none">
														{
															balances?.walletBalance?.[
																balances?.activeWallet
															]?.currency
														}
													</p>
													<img
														src={
															balances?.walletBalance?.[
																balances?.activeWallet
															]?.currencyLogo
														}
														className="w-3 mx-2"
														alt=""
													/>
													<div className="flex items-center leading-tight">
														{CurrencyFormatHelper.balanceCurrency(balances?.walletBalance?.[balances?.activeWallet]?.totalBalance, balances?.walletBalance?.[balances?.activeWallet]?.currencyType === 'fiat' ? 2 : 5)}
													</div>
												</div>
											</div>
										) : (
											<>
												<div
													className="block p-1 px-3"
													onClick={onClickBalance}
												>
													<div className="flex items-center gap-1">
														<img
															src={
																balances?.walletBalance?.[
																	balances?.activeWallet
																]?.currencyLogo
															}
															className="w-4 h-4"
															alt=""
														/>
														<p className="mobile-s:hidden laptop:flex leading-none">
															{
																balances?.walletBalance?.[
																	balances?.activeWallet
																]?.currency
															}
														</p>
														<Image
															src="/icons/Header/Balance/expand-white.png"
															loader={customLoader}
															width={22}
															height={22}
														/>
													</div>
													<p className="text-xs text-right md:text-sm leading-tight">
														{CurrencyFormatHelper.balanceCurrency(balances?.walletBalance?.[balances?.activeWallet]?.totalBalance, balances?.walletBalance?.[balances?.activeWallet]?.currencyType === 'fiat' ? 2 : 5)}
													</p>
												</div>
											</>
										)
									) : (
										<>
											<div className="border shadow rounded-md px-2 py-3 mx-auto">
												<div className="animate-pulse flex space-x-4 items-center justify-start">
													<div className="rounded-full shrink-0 bg-slate-700 h-6 w-6 text-center font-bold">$</div>
													<div className="w-full flex flex-col gap-2">
														<div className="h-2 w-10 bg-slate-700 rounded"></div>
														<div className="h-2 w-16 bg-slate-700 rounded"></div>
													</div>
												</div>
											</div>
										</>
									)}
								</div>

								<div
									style={{
										background: walletColor
									}}
									className={`${router.pathname == '/play' ? 'hidden' : 'block p-1 rounded-lg cursor-pointer min-h-12'}`}
									onClick={() => {
										setOnClickChooseCurrency(false);
										router.push('/account/wallet/deposit/');
									}}
								>
									<div className="flex items-center h-full text-black">
										<div className="p-2">
											<img className="max-w-[1.5rem] aspect-square" src={walletIcon} alt="" />
										</div>
										<span className="hidden laptop:block font-robotoBold text-xl pr-2">
											{appContext.t('menu.wallet')}
										</span>
									</div>
								</div>
							</div>
							<div className={'w-full'}>
								{onClickChooseCurrency && (
									<>
										<ChooseCurrencyModal
											{...p}
											setOnClickChooseCurrency={setOnClickChooseCurrency}
										/>
									</>
								)}
							</div>
						</div>
					</OutsideAlerter>
				</div>
			) : null}
		</>
	);
};

export default Balance;
