import { useContext, useEffect } from 'react';
import Footers from '../../organism/Footer/Footers';
import AffiliateContainer from '../../organism/Container/AffiliateContainer';
import AffiliateHeader from '../../organism/Header/AffiliateHeader';
import AppContext from '../../../context/app.context/app.context';
import { useRouter } from 'next/router';
import localStorage from 'localStorage';
import ModalLayoutAffiliate from './ModalLayoutAffiliate';

const menus = [
	{ label: 'Profile', link: '/affiliate/profile' },
	{ label: 'Player List', link: '/affiliate//player-list' },
	{ label: 'Sub Affiliate List', link: '/affiliate/sub-affiliate-list' },
	{ label: 'Commission Report', link: '/affiliate/commission-report' },
];

function LayoutAffiliate(p) {
	const { children } = p;
	const router = useRouter();
	const appContext = useContext(AppContext);

	useEffect(() => {
		if (!appContext.isAffiliateLoading) {
			if (!localStorage.getItem('affiliateToken')) {
				if (['/affiliate/login', '/affiliate/register'].includes(router.pathname)) {
					return;
				} else {
					return router.push('/affiliate/login');
				}
			}
			else {
				if (!['/affiliate/login', '/affiliate/register'].includes(router.pathname)) return;
				else return router.push('/affiliate/dashboard');
			}
		}
	}, [appContext]);

	p = {
		...p,
		menus
	};

	return (
		<ModalLayoutAffiliate {...p}>
			<AffiliateHeader {...p} />

			<AffiliateContainer {...p}>{children}</AffiliateContainer>

			<Footers {...p} />

		</ModalLayoutAffiliate>
	);
}

export default LayoutAffiliate;
