import BrandConfigStore from '../../../context/store/brand-config';

const ModalContent = (p) => {
	const { children } = p;
	const { colors } = BrandConfigStore(state => state.config.themeConfig.container);

	return (
		<div className="p-2 space-y-5" style={{ color: colors.primary }}>{children}</div>
	);
};

export { ModalContent };
