import { useSpring, animated } from '@react-spring/web';
import { ChatQueries } from '../../../graphql/subscriptions/chat';
import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useState, useContext, useRef } from 'react';
import AppContext from '../../../context/app.context/app.context';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BrandConfigStore from '../../../context/store/brand-config';
import GlobalStateStore from '../../../context/store/global-state';
import dayjs from 'dayjs';

const Chat = () => {
	const { themeConfig, brandId } = BrandConfigStore((state) => state.config);
	const { token } = GlobalStateStore((state) => ({ token: state.token }));
	const { container, footer } = themeConfig;
	const appContext = useContext(AppContext);
	const chatAreaRef = useRef(null);

	const [sendChatLoading, setSendChatLoading] = useState(false);
	const [chatMessage, setChatMessage] = useState('');
	const [showChat, setShowChat] = useState(false);
	const [roomList, setRoomList] = useState([]);
	const [skChat, setSkChat] = useState('');
	const chatType = useRef({
		key: undefined,
		name: undefined,
		id: ''
	});
	const [userAgent, setUserAgent] = useState({
		isMobile: null,
		name: ''
	});
	const [windowSize, setWindowSize] = useState({
		innerWidth: 0,
		innerHeight: 0
	});

	const [chatData, setChatData] = useState([]);
	const [chatSpring, chatSpringApi] = useSpring(() => ({
		from: { x: '100%' }
	}));

	const handleSend = () => {
		setSendChatLoading(true);
		API.graphql({
			query: ChatQueries.SendChat,
			variables: {
				id: skChat,
				message: chatMessage,
				brandId: brandId
			},
			authToken: appContext.token
		})
			.then(() => {
				// chatData.push(res.data.sendChat);
				// setChatData([...chatData]);
			})
			.finally(() => {
				setTimeout(() => {
					setChatMessage('');
					setSendChatLoading(false);
					scrollToBottom();
				}, 100);
			});
	};

	const handleChatTypeChange = (rl) => {
		chatType.current = {
			key: rl.sk,
			name: rl.name,
			id: brandId
		};

		setChatData([]);
		setSkChat(rl.sk);

		API.graphql({
			query: ChatQueries.GetAllChat,
			variables: {
				id: rl.sk
			},
			authToken: appContext.token
		})
			.then((res) => {
				const { chats } = res.data.getChatRoom;
				chats.reverse();
				setChatData((prev) => [...prev, ...chats]);
				scrollToBottom();
			})
			.catch((e) => console.log('error cuy', e));
	};
	const scrollToBottom = () => {
		chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight + 500;
	};

	const stringToColour = (str) => {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let color = '#';
		for (let i = 0; i < 3; i++) {
			let value = (hash >> (i * 8)) & 0xff;
			color += ('00' + value.toString(16)).substr(-2);
		}
		return color;
	};

	const chats =
		chatData.length == 0 ? (
			<div className="h-full"></div>
		) : (
			chatData.map((chat) => {
				const isSelf =
					`${appContext.userInfo.brandId}-${appContext.userInfo.username}` === chat.sender;
				const senderUsername = chat.sender.substring(chat.sender.indexOf('-') + 1);
				const background = stringToColour(senderUsername);
				const isDM = chatType.current.key === 'dm';
				return (
					<div className="chat flex flex-col text-xs py-2" key={chat.chatId}>
						<div className="flex justify-between items-center">
							<div
								className={`px-2 flex justify-start items-center gap-x-2 ${
									isSelf && isDM && 'flex-row-reverse'
								}`}>
								<div>
									<div
										className="w-6 h-6 font-semibold rounded-full flex justify-center items-center"
										style={{ background }}>
										{senderUsername.substring(0, 1)}
									</div>
								</div>
								{!isDM && (
									<h6
										className={`font-semibold text-gray-500 ${
											!isSelf && 'hover:underline cursor-pointer'
										}`}
										onClick={() => !isSelf && handleChatTypeChange()}>
										{senderUsername}
									</h6>
								)}

								<p className="text-gray-200 font-light">{chat.message}</p>
							</div>
							{console.log(container.colors)}
							<p className="text-gray-500">{dayjs(chat.sendAt).format('HH:mm')}</p>
						</div>
					</div>
				);
			})
		);

	useEffect(() => {
		if (appContext.token) {
			// get all global
			API.graphql({
				query: ChatQueries.GetAllChat,
				variables: {
					id: skChat,
					otherUser: ''
				},
				authToken: appContext.token
			})
				.then((res) => {
					const { chats } = res.data.getChatRoom;
					chats.reverse();
					setChatData([...chats]);
					scrollToBottom();
				})
				.catch((e) => console.log('error cuy', e));

			// get all rooms
			API.graphql({
				query: ChatQueries.GetAllRoom,
				variables: {
					id: brandId
				},
				authToken: appContext.token
			})
				.then((res) => {
					const { data } = res.data.getRoomList;

					setRoomList(data);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [token, showChat]);

	useEffect(() => {
		const subs = API.graphql(
			graphqlOperation(
				ChatQueries.ChatSubsriptions,
				{
					id: skChat
				},
				token
			)
		).subscribe({
			next: ({ value }) => {
				setChatData((prev) => [...prev, value.data.incomingChat]);
				scrollToBottom();
			}
		});

		return () => subs.unsubscribe();
	}, [token, skChat]);

	useEffect(() => {
		if (window) {
			setUserAgent({
				...userAgent,
				name: window.navigator.vendor,
				isMobile: 'ontouchstart' in document.documentElement
			});
		}
	}, [userAgent.name]);

	useEffect(() => {
		setWindowSize({ ...windowSize, innerHeight: window.innerHeight });
		window.addEventListener('resize', () => {
			setWindowSize({ ...windowSize, innerHeight: window.innerHeight });
		});
		window.addEventListener('orientationchange', () => {
			setWindowSize({ ...windowSize, innerHeight: window.innerHeight });
		});
		window.addEventListener('click', () => {
			setWindowSize({ ...windowSize, innerHeight: window.innerHeight });
		});
	}, [windowSize.innerHeight]);

	useEffect(() => {
		if (!showChat) {
			chatSpringApi.start({ x: '100%' });
		} else {
			chatSpringApi.start({ x: '0%' });
		}
	}, [showChat]);

	return (
		<>
			{token && token !== '' && !token.startsWith('sessionToken') ? (
				<div
					className="fixed bottom-16 left-10 bg-red-300 rounded-lg z-40 p-3 cursor-pointer md:bottom-10"
					onClick={() => setShowChat(!showChat)}>
					<ChatIcon />
				</div>
			) : null}

			{token && (
				<animated.div
					className="chat-container duration-150 fixed top-0 right-0 z-[500] w-full md:w-96"
					style={{
						height: userAgent.isMobile ? windowSize.innerHeight : windowSize.innerHeight,
						transform: chatSpring.x.to((x) => `translate3d(${x},0,0)`)
					}}>
					<div className="flex flex-col h-full">
						<header
							className="p-3 font-bold flex justify-between"
							style={{ background: container.backgroundColor.primary }}>
							<div
								className="flex gap-1 cursor-pointer"
								onClick={() => handleChatTypeChange('switch')}>
								<span>
									{chatType.current.key ? (
										<div className="space-x-2">
											<span>
												<ArrowBackIcon />
											</span>
											<span>{chatType.current.name}</span>
										</div>
									) : (
										'Select Room'
									)}
								</span>
							</div>
							<CloseIcon className="cursor-pointer" onClick={() => setShowChat(false)} />
						</header>
						<main
							id="chatArea"
							ref={chatAreaRef}
							className="h-full overflow-y-scroll px-3 overflow-x-hidden"
							style={{ background: footer.backgroundColor.primary }}>
							{chatType.current.key ? (
								chats
							) : (
								<div className="grid grid-cols-2 py-3 gap-3">
									{roomList.map((rl) => (
										<div
											key={rl.sk}
											className="flex flex-col p-3 rounded-lg cursor-pointer group"
											onClick={() => handleChatTypeChange(rl)}
											style={{ background: container.backgroundColor.primary }}>
											<div className="h-6 mb-3">
												<img src={rl.icon} className="h-full" />
											</div>
											<h6 className="mt-auto text-sm font-semibold group-hover:underline">
												{rl.name}
											</h6>
										</div>
									))}
									{/* {userDMs} */}
								</div>
							)}
						</main>
						{chatType.current.key === '' || (
							<div
								className="relative py-3 px-5"
								style={{ background: footer.backgroundColor.primary }}>
								<input
									id="chatBox"
									autoComplete="off"
									placeholder="Type your message here..."
									className="w-full text-gray-800 py-2 pr-14 text-sm rounded-full"
									type="text"
									onKeyDown={(e) => {
										if (e.code === 'Enter' || e.code === 'NumpadEnter') handleSend();
									}}
									value={chatMessage}
									onChange={(e) => setChatMessage(e.target.value)}
								/>
								<div
									className="w-10 flex justify-center mt-px p-1.5 px-2 absolute right-5 top-3 cursor-pointer rounded-full"
									onClick={handleSend}
									style={{ background: container.backgroundColor.primary }}>
									{sendChatLoading ? '...' : <SendIcon className="w-full" />}
								</div>
							</div>
						)}
					</div>
				</animated.div>
			)}
		</>
	);
};

export default Chat;
